import { Link } from "react-router-dom";
import Header from "../components/Header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../components/Footer";
import BackToTop from "../components/BackToTop";

const About = () => {
  return (
    <>
      <div id="main-wrapper">
        <Header activePage="About" />
        <section className="page-header page-header-text-light py-0 mb-0">
          <section className="hero-wrap section">
            <div className="hero-mask opacity-7 bg-dark" />
            <div
              className="hero-bg hero-bg-scroll"
              style={{ backgroundImage: 'url("images/bg/image-2.jpg")' }}
            />
            <div className="hero-content">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <h1 className="text-11 font-weight-500 text-white mb-3">
                      About Us
                    </h1>
                    <p className="text-5 text-white line-height-4 mb-4">
                      Our mission is to help you save on transfer fees and
                      exchange rates
                    </p>
                    <Link to="/signup" className="btn btn-primary m-2">
                      Open a Free Account
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <div id="content">
          <section className="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 d-flex">
                  <div className="my-auto px-0 px-lg-5 mx-2">
                    <h2 className="text-9">Who we are</h2>
                    <p className="text-4">
                      Welcome to Pacific Western Online Bank, your trusted
                      partner in the world of online banking. At Pacific
                      Western, we understand that financial well-being is a
                      cornerstone of a fulfilling life. That's why we are
                      dedicated to providing you with innovative, secure, and
                      convenient banking solutions tailored to your unique
                      needs.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 my-auto text-center">
                  <img
                    className="img-fluid shadow-lg rounded-lg"
                    src="images/who-we-are.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="section bg-white">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="row">
                    <div className="col-6 col-lg-7 ml-auto mb-lg-n5">
                      {" "}
                      <img
                        className="img-fluid rounded-lg shadow-lg"
                        src="images/our-values-vision.jpg"
                        alt="banner"
                      />{" "}
                    </div>
                    <div className="col-6 col-lg-8 mt-lg-n5">
                      {" "}
                      <img
                        className="img-fluid rounded-lg shadow-lg"
                        src="images/our-values-mission.jpg"
                        alt="banner"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex order-1 order-lg-2">
                  <div className="my-auto px-0 px-lg-5">
                    <h2 className="text-9 mb-4">Our Values</h2>
                    <h4 className="text-4 font-weight-500">Our Mission</h4>
                    <p className="tex-3">
                      At Pacific Western, your financial security and
                      satisfaction are our top priorities. We take every measure
                      to ensure that your personal and financial information is
                      protected with the highest level of encryption and
                      security protocols. Rest assured that your trust in us is
                      well-placed.
                    </p>
                    <h4 className="text-4 font-weight-500 mb-2">Our Vision</h4>
                    <p className="tex-3">
                      Our vision is simple yet powerful: to empower you to
                      achieve your financial goals and dreams. Whether you're
                      saving for your child's education, planning for
                      retirement, or starting a new business, we are here to
                      support you every step of the way. We believe that your
                      success is our success, and we are committed to helping
                      you prosper.
                    </p>
                    <h4 className="text-4 font-weight-500 mb-2">Our Goal</h4>
                    <p className="tex-3">
                      We constantly strive to stay at the forefront of
                      technological advancements in the banking industry. Our
                      cutting-edge digital banking platform allows you to manage
                      your finances anytime, anywhere, with ease. From mobile
                      banking to online bill pay, we offer a comprehensive suite
                      of digital services to simplify your financial life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="container">
              <h2 className="text-9 text-center">
                What people are saying about us
              </h2>
              <p className="lead text-center mb-4">
                A payments experience people love to talk about
              </p>
              <OwlCarousel
                className="owl-carousel owl-theme"
                autoplay
                nav
                loop
                margin={30}
                slideBy={2}
                stagePadding={5}
                items={1}
              >
                <div className="item">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “Switching to Pacific Western online banking was the best
                      decision I made for my business. Their online banking
                      services are incredibly user-friendly, and their support
                      team is always ready to assist. Thanks to them, managing
                      my finances has become a breeze.”
                    </p>
                    <strong className="d-block font-weight-500">
                      Jay Shah
                    </strong>{" "}
                    <span className="text-muted">
                      Founder at Icomatic Pvt Ltd
                    </span>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “As a college student, managing money can be challenging.
                      Pacific Western online has been a lifesaver. I can check
                      my balances, pay bills, and even save effortlessly. It's
                      the perfect bank for my generation.”
                    </p>
                    <strong className="d-block font-weight-500">
                      Patrick .M. Cary
                    </strong>{" "}
                    <span className="text-muted">Freelancer from USA</span>
                  </div>
                </div>
                <div className="item mh-100">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “Fast easy to use transfers to a different currency. Much
                      better value that the banks.”
                    </p>
                    <strong className="d-block font-weight-500">
                      De Mortel
                    </strong>{" "}
                    <span className="text-muted">Online Retail</span>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “I have used them twice now. Good rates, very efficient
                      service and it denies high street banks an undeserved
                      windfall. Excellent.”
                    </p>
                    <strong className="d-block font-weight-500">
                      Chris Tom
                    </strong>{" "}
                    <span className="text-muted">User from UK</span>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “It's a real good idea to manage your money by Pacific
                      Western. The rates are fair and you can carry out the
                      transactions without worrying!”
                    </p>
                    <strong className="d-block font-weight-500">
                      Mauri Lindberg
                    </strong>{" "}
                    <span className="text-muted">
                      Freelancer from Australia
                    </span>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “Only trying it out since a few days. But up to now
                      excellent. Seems to work flawlessly. I'm only using it for
                      sending money to friends at the moment.”
                    </p>
                    <strong className="d-block font-weight-500">
                      Dennis Jacques
                    </strong>{" "}
                    <span className="text-muted">User from USA</span>
                  </div>
                </div>
              </OwlCarousel>

              <div className="text-center mt-4">
                <a href="#/" className="btn-link text-4">
                  See more reviews
                  <i className="fas fa-chevron-right text-2 ml-2" />
                </a>
              </div>
            </div>
          </section>

          <section className="section bg-primary py-5">
            <div className="container text-center">
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <div className="featured-box text-center">
                    <div className="featured-box-icon text-light mb-2">
                      {" "}
                      <i className="fas fa-globe" />{" "}
                    </div>
                    <h4 className="text-12 text-white mb-0">180+</h4>
                    <p className="text-4 text-white mb-0">Countries</p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="featured-box text-center">
                    <div className="featured-box-icon text-light mb-2">
                      {" "}
                      <i className="fas fa-dollar-sign" />{" "}
                    </div>
                    <h4 className="text-12 text-white mb-0">120</h4>
                    <p className="text-4 text-white mb-0">Currencies</p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 mt-4 mt-md-0">
                  <div className="featured-box text-center">
                    <div className="featured-box-icon text-light mb-2">
                      {" "}
                      <i className="fas fa-users" />{" "}
                    </div>
                    <h4 className="text-12 text-white mb-0">2.5M</h4>
                    <p className="text-4 text-white mb-0">Users</p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 mt-4 mt-md-0">
                  <div className="featured-box text-center">
                    <div className="featured-box-icon text-light mb-2">
                      {" "}
                      <i className="far fa-life-ring" />{" "}
                    </div>
                    <h4 className="text-12 text-white mb-0">24X7</h4>
                    <p className="text-4 text-white mb-0">Support</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
      <BackToTop />
    </>
  );
};

export default About;
