import { useState, useEffect, FormEventHandler } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardHeader from "../../components/DashboardHeader";
import Footer from "../../components/Footer";
import BackToTop from "../../components/BackToTop";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../../assets/avatar.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";
import Preloader from "../../components/Preloader";
import { logoutUser } from "../../store/redux/userRedux";

const Profile = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<any>();
  const [fileUploading, setFileUploading] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [changesMade, setChangesMade] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [occupation, setOccupation] = useState("");

  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
      setFileUploading(true);
    } else {
      setImage(null);
      setFileUploading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("pacwest");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://api.pacwesternonline.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        setFirstName(data.firstName);
        setMiddleName(data.middleName);
        setLastName(data.lastName);
        setEmail(data.email);
        setPhone(data.phone);
        setAddress(data.address);
        setOccupation(data.occupation);
        setTimeout(() => {
          setPageLoading(false);
        }, 500);
      } catch (error: any) {
        if (error.response) {
          if (error.response.data === "Token is not valid!") {
            localStorage.removeItem("pacwest");
            dispatch(logoutUser());
          } else {
            localStorage.removeItem("pacwest");
            dispatch(logoutUser());
          }
        }
      }
    };

    getUser();
  }, [dispatch]);

  useEffect(() => {
    if (fileUploading) {
      const fileName = new Date().getTime() + image.name;
      const storage = getStorage(app);
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFileProgress(Math.round(progress));
        },
        (error) => {
          // Handle unsuccessful uploads
          toast.error("Something went wrong");
          setFileUploading(false);
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const editImage = async () => {
              const token = localStorage.getItem("pacwest");

              try {
                await axios.put(
                  `https://api.pacwesternonline.com/api/user/${user?._id}`,
                  {
                    picture: downloadURL,
                  },
                  {
                    headers: { token: `Bearer ${token}` },
                  }
                );

                setFileUploading(false);
                toast.success("Image updated");
                setTimeout(() => {
                  navigate(0);
                }, 1800);
              } catch (error) {
                setFileUploading(false);
                toast.error("Something went wrong");
              }
            };

            editImage();
          });
        }
      );
    }
  }, [image, navigate, fileUploading, user?._id]);

  const editUser: FormEventHandler = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("pacwest");

    try {
      setLoading(true);

      await axios.put(
        `https://api.pacwesternonline.com/api/user/${user?._id}`,
        {
          firstName,
          middleName,
          lastName,
          email,
          phone,
          occupation,
          address,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Changes saved");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      {pageLoading && <Preloader />}
      <div id="main-wrapper">
        <DashboardHeader />
        <div className="bg-primary">
          <div className="container d-flex justify-content-center">
            <ul className="nav secondary-nav">
              <li className="nav-item">
                {" "}
                <Link className="nav-link active" to="/profile">
                  Account
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link className="nav-link" to="/security">
                  Security
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link className="nav-link" to="/payment-methods">
                  Payment Methods
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div id="content" className="py-4">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3">
                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="profile-thumb mt-3 mb-4">
                    {user?.picture ? (
                      <img
                        className="rounded-circle"
                        src={user?.picture}
                        alt="avatar"
                        style={{ width: "100px" }}
                      />
                    ) : (
                      <img
                        className="rounded-circle"
                        src={avatar}
                        alt="avatar"
                        style={{ width: "100px" }}
                      />
                    )}
                    <div
                      className="profile-thumb-edit custom-file bg-primary text-white"
                      data-toggle="tooltip"
                      title="Change Profile Picture"
                    >
                      {" "}
                      <i className="fas fa-camera position-absolute" />
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        onChange={handleChange}
                        accept="image/*"
                      />
                    </div>
                  </div>
                  <p className="text-3 font-weight-500 mb-0">
                    Hello, {user.firstName} {user?.middleName} {user.lastName}
                  </p>
                  <p className="text-2 font-weight-400 mb-2">
                    Account ID: {user.accountNumber}
                  </p>
                  {fileUploading && (
                    <p className="mb-2">Uploading {fileProgress}%...</p>
                  )}
                </div>
                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="text-17 text-light my-3">
                    <i className="fas fa-wallet" />
                  </div>
                  <h3 className="text-9 font-weight-400">
                    ${user.balance?.toLocaleString()}
                  </h3>
                  <p className="mb-2 text-muted opacity-8">Available Balance</p>
                </div>

                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="text-17 text-light my-3">
                    <i className="fas fa-comments" />
                  </div>
                  <h3 className="text-5 font-weight-400 my-4">Need Help?</h3>
                  <p className="text-muted opacity-8 mb-4">
                    Have questions or concerns regarding your account?
                    <br />
                    Our experts are here to help.
                  </p>
                  <a href="#/" className="btn btn-primary btn-block">
                    Chat with Us
                  </a>
                </div>
              </aside>

              <div className="col-lg-9">
                <div className="bg-white shadow-sm rounded p-4 mb-4">
                  <h3 className="text-5 font-weight-400 d-flex align-items-center mb-4">
                    Personal Details
                    <a
                      href="#edit-personal-details"
                      data-toggle="modal"
                      className="ml-auto text-2 text-uppercase btn-link"
                    >
                      <span className="mr-1">
                        <i className="fas fa-edit" />
                      </span>
                      Edit
                    </a>
                  </h3>
                  <hr className="mx-n4 mb-4" />
                  <div className="form-row align-items-center">
                    <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                      First Name:
                    </p>
                    <p className="col-sm-9 text-3">{user?.firstName}</p>
                  </div>
                  <div className="form-row align-items-center">
                    <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                      Middle Name:
                    </p>
                    <p className="col-sm-9 text-3">{user?.middleName}</p>
                  </div>
                  <div className="form-row align-items-center">
                    <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                      Last Name:
                    </p>
                    <p className="col-sm-9 text-3">{user?.lastName}</p>
                  </div>
                  <div className="form-row align-items-center">
                    <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                      Occupation:
                    </p>
                    <p className="col-sm-9 text-3">{user?.occupation}</p>
                  </div>
                  <div className="form-row align-items-baseline">
                    <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                      Address:
                    </p>
                    <p className="col-sm-9 text-3">{user?.address}</p>
                  </div>
                </div>

                <div
                  id="edit-personal-details"
                  className="modal fade "
                  role="dialog"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title font-weight-400">
                          Personal Details
                        </h5>
                        <button
                          type="button"
                          className="close font-weight-400"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          {" "}
                          <span aria-hidden="true">×</span>{" "}
                        </button>
                      </div>
                      <div className="modal-body p-4">
                        <form id="personaldetails" onSubmit={editUser}>
                          <div className="row">
                            <div className="col-12 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  data-bv-field="firstName"
                                  id="firstName"
                                  required
                                  value={firstName}
                                  onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="middleName">Middle Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  data-bv-field="middleName"
                                  id="middleName"
                                  value={middleName}
                                  onChange={(e) => {
                                    setMiddleName(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  data-bv-field="lastName"
                                  id="lastName"
                                  required
                                  value={lastName}
                                  onChange={(e) => {
                                    setLastName(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="occupation">Occupation</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  data-bv-field="occupation"
                                  id="occupation"
                                  value={occupation}
                                  placeholder="Occupation"
                                  onChange={(e) => {
                                    setOccupation(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <h3 className="text-5 font-weight-400 mt-3">
                                Address
                              </h3>
                              <hr />
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="address">Address</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  data-bv-field="address"
                                  id="address"
                                  placeholder="Address"
                                  value={address}
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {changesMade && (
                            <>
                              {loading ? (
                                <button
                                  className="btn btn-primary btn-block mt-2"
                                  type="button"
                                  disabled
                                >
                                  Saving Changes...
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary btn-block mt-2"
                                  type="submit"
                                >
                                  Save Changes
                                </button>
                              )}
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow-sm rounded p-4 mb-4">
                  <h3 className="text-5 font-weight-400 d-flex align-items-center mb-4">
                    Account Settings
                    <a
                      href="#edit-account-settings"
                      data-toggle="modal"
                      className="ml-auto text-2 text-uppercase btn-link"
                    >
                      <span className="mr-1">
                        <i className="fas fa-edit" />
                      </span>
                      Edit
                    </a>
                  </h3>
                  <hr className="mx-n4 mb-4" />
                  <div className="form-row align-items-center">
                    <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                      Language:
                    </p>
                    <p className="col-sm-9 text-3">English (United States)</p>
                  </div>
                  <div className="form-row align-items-center">
                    <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                      Time Zone:
                    </p>
                    <p className="col-sm-9 text-3">
                      (GMT-06:00) Central America
                    </p>
                  </div>
                  <div className="form-row align-items-center">
                    <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                      Account Status:
                    </p>
                    <p className="col-sm-9 text-3">
                      <span className="bg-success text-white rounded-pill d-inline-block px-2 mb-0">
                        <i className="fas fa-check-circle" /> Active
                      </span>
                    </p>
                  </div>
                </div>
                {/* Edit Details Modal
    ================================== */}
                <div
                  id="edit-account-settings"
                  className="modal fade "
                  role="dialog"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title font-weight-400">
                          Account Settings
                        </h5>
                        <button
                          type="button"
                          className="close font-weight-400"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          {" "}
                          <span aria-hidden="true">×</span>{" "}
                        </button>
                      </div>
                      <div className="modal-body p-4">
                        <form
                          id="accountSettings"
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="language">Language</label>
                                <select
                                  className="custom-select"
                                  id="language"
                                  name="language_id"
                                >
                                  <option value={1}>
                                    English (United States)
                                  </option>
                                  <option value={2}>Spanish </option>
                                  <option value={3}>Chinese</option>
                                  <option value={4}>Russian</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="input-timezone">
                                  Time Zone
                                </label>
                                <select
                                  className="custom-select"
                                  id="input-timezone"
                                  name="timezone_id"
                                >
                                  <option value={-12}>
                                    (GMT-12:00) International Date Line West
                                  </option>
                                  <option value={-11}>
                                    (GMT-11:00) Midway Island, Samoa
                                  </option>
                                  <option value={-10}>
                                    (GMT-10:00) Hawaii
                                  </option>
                                  <option value={-9}>(GMT-09:00) Alaska</option>
                                  <option value={-8}>
                                    (GMT-08:00) Pacific Time (US &amp; Canada)
                                  </option>
                                  <option value={-8}>
                                    (GMT-08:00) Tijuana, Baja California
                                  </option>
                                  <option value={-7}>
                                    (GMT-07:00) Arizona
                                  </option>
                                  <option value={-7}>
                                    (GMT-07:00) Chihuahua, La Paz, Mazatlan
                                  </option>
                                  <option value={-7}>
                                    (GMT-07:00) Mountain Time (US &amp; Canada)
                                  </option>
                                  <option selected value={-6}>
                                    (GMT-06:00) Central America
                                  </option>
                                  <option value={-6}>
                                    (GMT-06:00) Central Time (US &amp; Canada)
                                  </option>
                                  <option value={-6}>
                                    (GMT-06:00) Guadalajara, Mexico City,
                                    Monterrey
                                  </option>
                                  <option value={-6}>
                                    (GMT-06:00) Saskatchewan
                                  </option>
                                  <option value={-5}>
                                    (GMT-05:00) Bogota, Lima, Quito, Rio Branco
                                  </option>
                                  <option value={-5}>
                                    (GMT-05:00) Eastern Time (US &amp; Canada)
                                  </option>
                                  <option value={-5}>
                                    (GMT-05:00) Indiana (East)
                                  </option>
                                  <option value={-4}>
                                    (GMT-04:00) Atlantic Time (Canada)
                                  </option>
                                  <option value={-4}>
                                    (GMT-04:00) Caracas, La Paz
                                  </option>
                                  <option value={-4}>(GMT-04:00) Manaus</option>
                                  <option value={-4}>
                                    (GMT-04:00) Santiago
                                  </option>
                                  <option value="-3.5">
                                    (GMT-03:30) Newfoundland
                                  </option>
                                  <option value={-3}>
                                    (GMT-03:00) Brasilia
                                  </option>
                                  <option value={-3}>
                                    (GMT-03:00) Buenos Aires, Georgetown
                                  </option>
                                  <option value={-3}>
                                    (GMT-03:00) Greenland
                                  </option>
                                  <option value={-3}>
                                    (GMT-03:00) Montevideo
                                  </option>
                                  <option value={-2}>
                                    (GMT-02:00) Mid-Atlantic
                                  </option>
                                  <option value={-1}>
                                    (GMT-01:00) Cape Verde Is.
                                  </option>
                                  <option value={-1}>(GMT-01:00) Azores</option>
                                  <option value={0}>
                                    (GMT+00:00) Casablanca, Monrovia, Reykjavik
                                  </option>
                                  <option value={0}>
                                    (GMT+00:00) Greenwich Mean Time : Dublin,
                                    Edinburgh, Lisbon, London
                                  </option>
                                  <option value={1}>
                                    (GMT+01:00) Amsterdam, Berlin, Bern, Rome,
                                    Stockholm, Vienna
                                  </option>
                                  <option value={1}>
                                    (GMT+01:00) Belgrade, Bratislava, Budapest,
                                    Ljubljana, Prague
                                  </option>
                                  <option value={1}>
                                    (GMT+01:00) Brussels, Copenhagen, Madrid,
                                    Paris
                                  </option>
                                  <option value={1}>
                                    (GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb
                                  </option>
                                  <option value={1}>
                                    (GMT+01:00) West Central Africa
                                  </option>
                                  <option value={2}>(GMT+02:00) Amman</option>
                                  <option value={2}>
                                    (GMT+02:00) Athens, Bucharest, Istanbul
                                  </option>
                                  <option value={2}>(GMT+02:00) Beirut</option>
                                  <option value={2}>(GMT+02:00) Cairo</option>
                                  <option value={2}>
                                    (GMT+02:00) Harare, Pretoria
                                  </option>
                                  <option value={2}>
                                    (GMT+02:00) Helsinki, Kyiv, Riga, Sofia,
                                    Tallinn, Vilnius
                                  </option>
                                  <option value={2}>
                                    (GMT+02:00) Jerusalem
                                  </option>
                                  <option value={2}>(GMT+02:00) Minsk</option>
                                  <option value={2}>
                                    (GMT+02:00) Windhoek
                                  </option>
                                  <option value={3}>
                                    (GMT+03:00) Kuwait, Riyadh, Baghdad
                                  </option>
                                  <option value={3}>
                                    (GMT+03:00) Moscow, St. Petersburg,
                                    Volgograd
                                  </option>
                                  <option value={3}>(GMT+03:00) Nairobi</option>
                                  <option value={3}>(GMT+03:00) Tbilisi</option>
                                  <option value="3.5">
                                    (GMT+03:30) Tehran
                                  </option>
                                  <option value={4}>
                                    (GMT+04:00) Abu Dhabi, Muscat
                                  </option>
                                  <option value={4}>(GMT+04:00) Baku</option>
                                  <option value={4}>(GMT+04:00) Yerevan</option>
                                  <option value="4.5">(GMT+04:30) Kabul</option>
                                  <option value={5}>
                                    (GMT+05:00) Yekaterinburg
                                  </option>
                                  <option value={5}>
                                    (GMT+05:00) Islamabad, Karachi, Tashkent
                                  </option>
                                  <option value="5.5">
                                    (GMT+05:30) Sri Jayawardenapura
                                  </option>
                                  <option value="5.5">
                                    (GMT+05:30) Chennai, Kolkata, Mumbai, New
                                    Delhi
                                  </option>
                                  <option value="5.75">
                                    (GMT+05:45) Kathmandu
                                  </option>
                                  <option value={6}>
                                    (GMT+06:00) Almaty, Novosibirsk
                                  </option>
                                  <option value={6}>
                                    (GMT+06:00) Astana, Dhaka
                                  </option>
                                  <option value="6.5">
                                    (GMT+06:30) Yangon (Rangoon)
                                  </option>
                                  <option value={7}>
                                    (GMT+07:00) Bangkok, Hanoi, Jakarta
                                  </option>
                                  <option value={7}>
                                    (GMT+07:00) Krasnoyarsk
                                  </option>
                                  <option value={8}>
                                    (GMT+08:00) Beijing, Chongqing, Hong Kong,
                                    Urumqi
                                  </option>
                                  <option value={8}>
                                    (GMT+08:00) Kuala Lumpur, Singapore
                                  </option>
                                  <option value={8}>
                                    (GMT+08:00) Irkutsk, Ulaan Bataar
                                  </option>
                                  <option value={8}>(GMT+08:00) Perth</option>
                                  <option value={8}>(GMT+08:00) Taipei</option>
                                  <option value={9}>
                                    (GMT+09:00) Osaka, Sapporo, Tokyo
                                  </option>
                                  <option value={9}>(GMT+09:00) Seoul</option>
                                  <option value={9}>(GMT+09:00) Yakutsk</option>
                                  <option value="9.5">
                                    (GMT+09:30) Adelaide
                                  </option>
                                  <option value="9.5">
                                    (GMT+09:30) Darwin
                                  </option>
                                  <option value={10}>
                                    (GMT+10:00) Brisbane
                                  </option>
                                  <option value={10}>
                                    (GMT+10:00) Canberra, Melbourne, Sydney
                                  </option>
                                  <option value={10}>(GMT+10:00) Hobart</option>
                                  <option value={10}>
                                    (GMT+10:00) Guam, Port Moresby
                                  </option>
                                  <option value={10}>
                                    (GMT+10:00) Vladivostok
                                  </option>
                                  <option value={11}>
                                    (GMT+11:00) Magadan, Solomon Is., New
                                    Caledonia
                                  </option>
                                  <option value={12}>
                                    (GMT+12:00) Auckland, Wellington
                                  </option>
                                  <option value={12}>
                                    (GMT+12:00) Fiji, Kamchatka, Marshall Is.
                                  </option>
                                  <option value={13}>
                                    (GMT+13:00) Nuku'alofa
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="accountStatus">
                                  Account Status
                                </label>
                                <select
                                  className="custom-select"
                                  id="accountStatus"
                                  name="language_id"
                                >
                                  <option value={1}>Active</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <button
                            className="btn btn-primary btn-block mt-2"
                            type="submit"
                          >
                            Save Changes
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Account Settings End */}

                <div className="bg-white shadow-sm rounded p-4 mb-4">
                  <h3 className="text-5 font-weight-400 d-flex align-items-center mb-4">
                    Email Address
                    <a
                      href="#edit-email"
                      data-toggle="modal"
                      className="ml-auto text-2 text-uppercase btn-link"
                    >
                      <span className="mr-1">
                        <i className="fas fa-edit" />
                      </span>
                      Edit
                    </a>
                  </h3>
                  <hr className="mx-n4 mb-4" />

                  <div className="form-row align-items-center">
                    <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                      Email address:
                    </p>
                    <p className="col-sm-9 text-3">{user?.email}</p>
                  </div>
                </div>

                <div
                  id="edit-email"
                  className="modal fade "
                  role="dialog"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title font-weight-400">
                          Email Address
                        </h5>
                        <button
                          type="button"
                          className="close font-weight-400"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          {" "}
                          <span aria-hidden="true">×</span>{" "}
                        </button>
                      </div>
                      <div className="modal-body p-4">
                        <form id="emailAddresses" onSubmit={editUser}>
                          <div className="form-group">
                            <label htmlFor="email">Email address</label>
                            <input
                              type="email"
                              className="form-control"
                              data-bv-field="email"
                              id="email"
                              required
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setChangesMade(true);
                              }}
                            />
                          </div>

                          {changesMade && (
                            <>
                              {loading ? (
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  disabled
                                >
                                  Saving Changes...
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                >
                                  Save Changes
                                </button>
                              )}
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow-sm rounded p-4 mb-4">
                  <h3 className="text-5 font-weight-400 d-flex align-items-center mb-4">
                    Phone
                    <a
                      href="#edit-phone"
                      data-toggle="modal"
                      className="ml-auto text-2 text-uppercase btn-link"
                    >
                      <span className="mr-1">
                        <i className="fas fa-edit" />
                      </span>
                      Edit
                    </a>
                  </h3>
                  <hr className="mx-n4 mb-4" />

                  <div className="form-row align-items-center">
                    <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                      Mobile:
                    </p>
                    <p className="col-sm-9 text-3">{user?.phone}</p>
                  </div>
                </div>

                <div
                  id="edit-phone"
                  className="modal fade "
                  role="dialog"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title font-weight-400">Phone</h5>
                        <button
                          type="button"
                          className="close font-weight-400"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          {" "}
                          <span aria-hidden="true">×</span>{" "}
                        </button>
                      </div>
                      <div className="modal-body p-4">
                        <form id="phone" onSubmit={editUser}>
                          <div className="form-group">
                            <label htmlFor="phone">Mobile</label>
                            <div className="input-group">
                              <input
                                type="tel"
                                className="form-control"
                                data-bv-field="phone"
                                id="phone"
                                required
                                value={phone}
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                          </div>

                          {changesMade && (
                            <>
                              {loading ? (
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  disabled
                                >
                                  Saving Changes...
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                >
                                  Save Changes
                                </button>
                              )}
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <BackToTop />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </>
  );
};

export default Profile;
