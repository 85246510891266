import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadingUser,
  loadUser,
  logoutUser,
  noUser,
} from "./store/redux/userRedux";
import axios from "axios";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Fees from "./pages/Fees";
import Help from "./pages/Help";
import Home from "./pages/Home";
import Signup from "./pages/auth/Signup";
import OtpVerification from "./pages/auth/OtpVerification";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Dashboard from "./pages/dashboard/Dashboard";
import SendMoney from "./pages/dashboard/SendMoney";
import Profile from "./pages/dashboard/Profile";
import Security from "./pages/dashboard/Security";
import PaymentMethods from "./pages/dashboard/PaymentMethods";
import Transactions from "./pages/dashboard/Transactions";

const App = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingUser());
    const token = localStorage.getItem("pacwest");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://api.pacwesternonline.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        dispatch(loadUser(data));
      } catch (error: any) {
        if (error.response) {
          if (error.response.data === "Token is not valid!") {
            localStorage.removeItem("pacwest");
            dispatch(logoutUser());
          }
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route
        path="/dashboard"
        element={user ? <Dashboard /> : <Navigate replace to={"/login"} />}
      />
      <Route path="/fees" element={<Fees />} />
      <Route
        path="/forgot-password"
        element={
          !user ? <ForgotPassword /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route path="/help" element={<Help />} />
      <Route
        path="/login"
        element={!user ? <Login /> : <Navigate replace to={"/dashboard"} />}
      />
      <Route
        path="/otp-verification"
        element={
          !user ? <OtpVerification /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/passwordreset/:resetToken"
        element={
          !user ? <ResetPassword /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/payment-methods"
        element={user ? <PaymentMethods /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/profile"
        element={user ? <Profile /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/security"
        element={user ? <Security /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/send-money"
        element={user ? <SendMoney /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/signup"
        element={!user ? <Signup /> : <Navigate replace to={"/dashboard"} />}
      />
      <Route
        path="/transactions"
        element={user ? <Transactions /> : <Navigate replace to={"/login"} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
