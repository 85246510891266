import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Header = (props: any) => {
  const user = useSelector((state: any) => state.user.currentUser);

  return (
    <header id="header">
      <div className="container">
        <div className="header-row">
          <div className="header-column justify-content-start">
            <div className="logo">
              <Link className="d-flex" to="/">
                <span className="logo-desktop-no-text" aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="45"
                    viewBox="0 0 120 59"
                  >
                    <path
                      d="M79.803,54.975l25.1232-14.9218-28.0788,7.4609,22.069-28.0765-27.6847,22.4809,6.8966-27.193-14.1872,24.0516L60,0l-3.9409,38.777-13.8916-24.1498,6.601,27.193L21.0837,19.1431l22.1675,28.371-28.1773-7.7554,25.2217,15.2163L0,59H53.0049v-.589c0-3.9268,3.1527-7.1664,6.9951-7.1664s6.9951,3.2396,6.9951,7.1664v.589h53.0049l-40.197-4.025Z"
                      style={{ fill: "#2dbe60" }}
                    ></path>
                  </svg>
                </span>
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#header-nav"
            >
              {" "}
              <span />
              <span /> <span />{" "}
            </button>
            <nav className="primary-menu navbar navbar-expand-lg">
              <div id="header-nav" className="collapse navbar-collapse">
                <ul className="navbar-nav mr-auto">
                  <li
                    className={`${props.activePage === "Home" ? "active" : ""}`}
                  >
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className={`${
                      props.activePage === "About" ? "active" : ""
                    }`}
                  >
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li
                    className={`${props.activePage === "Fees" ? "active" : ""}`}
                  >
                    <Link to="/fees">Fees</Link>
                  </li>
                  <li
                    className={`${props.activePage === "Help" ? "active" : ""}`}
                  >
                    <Link to="/help">Help</Link>
                  </li>
                  <li
                    className={`${
                      props.activePage === "Contact" ? "active" : ""
                    }`}
                  >
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="header-column justify-content-end">
            {user ? (
              <nav className="login-signup navbar navbar-expand">
                <ul className="navbar-nav">
                  <li className="align-items-center h-auto ml-sm-3">
                    <Link className="btn btn-primary" to="/dashboard">
                      Dashboard
                    </Link>
                  </li>
                </ul>
              </nav>
            ) : (
              <nav className="login-signup navbar navbar-expand">
                <ul className="navbar-nav">
                  <li>
                    <Link to="/login">Login</Link>{" "}
                  </li>
                  <li className="align-items-center h-auto ml-sm-3">
                    <Link className="btn btn-primary" to="/signup">
                      Sign Up
                    </Link>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
