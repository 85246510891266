import { Link } from "react-router-dom";
import BackToTop from "../components/BackToTop";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Help = () => {
  return (
    <>
      <div id="main-wrapper">
        <Header activePage="Help" />
        <section className="hero-wrap section">
          <div className="hero-mask opacity-9 bg-primary" />
          <div
            className="hero-bg"
            style={{ backgroundImage: 'url("images/bg/image-2.jpg")' }}
          />
          <div className="hero-content">
            <div className="container">
              <div className="row align-items-center text-center">
                <div className="col-12">
                  <h1 className="text-11 text-white mb-4">
                    How can we help you?
                  </h1>
                </div>
                <div className="col-md-10 col-lg-8 col-xl-6 mx-auto">
                  <div className="input-group">
                    <input
                      className="form-control shadow-none border-0"
                      type="search"
                      id="search-input"
                      placeholder="Search for answer..."
                    />
                    <div className="input-group-append">
                      {" "}
                      <span className="input-group-text bg-white border-0 p-0">
                        <button
                          className="btn text-muted shadow-none px-3 border-0"
                          type="button"
                        >
                          <i className="fa fa-search" />
                        </button>
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="content">
          <section className="section py-3 my-3 py-sm-5 my-sm-5">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                  <div className="bg-white shadow-sm rounded p-4 text-center">
                    {" "}
                    <span className="d-block text-17 text-primary mt-2 mb-3">
                      <i className="fas fa-user-circle" />
                    </span>
                    <h3 className="text-body text-4">My Account</h3>
                    <p className="mb-0">
                      <a className="text-muted btn-link" href="#/">
                        See articles
                        <span className="text-1 ml-1">
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                  <div className="bg-white shadow-sm rounded p-4 text-center">
                    {" "}
                    <span className="d-block text-17 text-primary mt-2 mb-3">
                      <i className="fas fa-money-check-alt" />
                    </span>
                    <h3 className="text-body text-4">Payment</h3>
                    <p className="mb-0">
                      <a className="text-muted btn-link" href="#/">
                        See articles
                        <span className="text-1 ml-1">
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-4 mb-sm-0">
                  <div className="bg-white shadow-sm rounded p-4 text-center">
                    {" "}
                    <span className="d-block text-17 text-primary mt-2 mb-3">
                      <i className="fas fa-shield-alt" />
                    </span>
                    <h3 className="text-body text-4">Security</h3>
                    <p className="mb-0">
                      <a className="text-muted btn-link" href="#/">
                        See articles
                        <span className="text-1 ml-1">
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="bg-white shadow-sm rounded p-4 text-center">
                    {" "}
                    <span className="d-block text-17 text-primary mt-2 mb-3">
                      <i className="fas fa-credit-card" />
                    </span>
                    <h3 className="text-body text-4">Payment Methods</h3>
                    <p className="mb-0">
                      <a className="text-muted btn-link" href="#/">
                        See articles
                        <span className="text-1 ml-1">
                          <i className="fas fa-chevron-right" />
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section bg-white">
            <div className="container">
              <h2 className="text-9 text-center">Popular Topics</h2>
              <p className="text-4 text-center mb-5">
                Frequenty asked questions and answers
              </p>
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="accordion accordion-alternate"
                        id="popularTopics"
                      >
                        <div className="card">
                          <div className="card-header" id="heading1">
                            <h5 className="mb-0">
                              {" "}
                              <a
                                href="#/"
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#collapse1"
                                aria-expanded="false"
                                aria-controls="collapse1"
                              >
                                I forgot the password for my account
                              </a>
                            </h5>
                          </div>
                          <div
                            id="collapse1"
                            className="collapse"
                            aria-labelledby="heading1"
                            data-parent="#popularTopics"
                          >
                            <div className="card-body">
                              Simply click on the 'Forgot Password' link on the
                              login page to reset your password.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading2">
                            <h5 className="mb-0">
                              {" "}
                              <a
                                href="#/"
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#collapse2"
                                aria-expanded="false"
                                aria-controls="collapse2"
                              >
                                How can I open an account?
                              </a>
                            </h5>
                          </div>
                          <div
                            id="collapse2"
                            className="collapse"
                            aria-labelledby="heading2"
                            data-parent="#popularTopics"
                          >
                            <div className="card-body">
                              To open an account with us, simply go the Signup
                              page and follow the easy online application
                              process.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading3">
                            <h5 className="mb-0">
                              {" "}
                              <a
                                href="#/"
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#collapse3"
                                aria-expanded="false"
                                aria-controls="collapse3"
                              >
                                Is my money safe here?
                              </a>{" "}
                            </h5>
                          </div>
                          <div
                            id="collapse3"
                            className="collapse"
                            aria-labelledby="heading3"
                            data-parent="#popularTopics"
                          >
                            <div className="card-body">
                              Yes, your money is safe with us. We are FDIC
                              insured, which means your deposits are protected
                              up to $250,000 per account.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading4">
                            <h5 className="mb-0">
                              {" "}
                              <a
                                href="#/"
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#collapse4"
                                aria-expanded="false"
                                aria-controls="collapse4"
                              >
                                Can I access my account online and via online
                                banking?
                              </a>{" "}
                            </h5>
                          </div>
                          <div
                            id="collapse4"
                            className="collapse"
                            aria-labelledby="heading4"
                            data-parent="#popularTopics"
                          >
                            <div className="card-body">
                              Absolutely! We offer a robust online banking
                              platform that allows you to manage your accounts,
                              pay bills, and make transfers 24/7 from your
                              computer, tablet or mobile device.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading5">
                            <h5 className="mb-0">
                              {" "}
                              <a
                                href="#/"
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#collapse5"
                                aria-expanded="false"
                                aria-controls="collapse5"
                              >
                                Are there any fees associated with your
                                accounts?
                              </a>{" "}
                            </h5>
                          </div>
                          <div
                            id="collapse5"
                            className="collapse"
                            aria-labelledby="heading5"
                            data-parent="#popularTopics"
                          >
                            <div className="card-body">
                              We strive to keep our fees competitive and
                              transparent. Many of our accounts have no monthly
                              maintenance fees, but specific fees may apply
                              based on the type of account and services you use.
                              You can review our fee schedule{" "}
                              <Link to="/fees">here</Link>.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="accordion accordion-alternate"
                        id="popularTopics2"
                      >
                        <div className="card">
                          <div className="card-header" id="heading6">
                            <h5 className="mb-0">
                              {" "}
                              <a
                                href="#/"
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#collapse6"
                                aria-expanded="false"
                                aria-controls="collapse6"
                              >
                                How Can I View My Payments History?
                              </a>
                            </h5>
                          </div>
                          <div
                            id="collapse6"
                            className="collapse"
                            aria-labelledby="heading6"
                            data-parent="#popularTopics2"
                          >
                            <div className="card-body">
                              You can view your transaction history from your
                              dashboard.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading7">
                            <h5 className="mb-0">
                              {" "}
                              <a
                                href="#/"
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#collapse7"
                                aria-expanded="false"
                                aria-controls="collapse7"
                              >
                                What is your customer service availability?
                              </a>{" "}
                            </h5>
                          </div>
                          <div
                            id="collapse7"
                            className="collapse"
                            aria-labelledby="heading7"
                            data-parent="#popularTopics2"
                          >
                            <div className="card-body">
                              Our customer service team is available during
                              regular business hours, and you can reach them
                              through our toll-free number. Additionally, you
                              can send us a secure message through your online
                              banking portal at any time.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading8">
                            <h5 className="mb-0">
                              {" "}
                              <a
                                href="#/"
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#collapse8"
                                aria-expanded="false"
                                aria-controls="collapse8"
                              >
                                Do you offer credit cards and loans?
                              </a>{" "}
                            </h5>
                          </div>
                          <div
                            id="collapse8"
                            className="collapse"
                            aria-labelledby="heading8"
                            data-parent="#popularTopics2"
                          >
                            <div className="card-body">
                              Yes, we offer credit cards and a range of loan
                              options, including personal loans, home mortgages,
                              and business loans. You can apply for these
                              services through our website or speak with one of
                              our loan specialists.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading9">
                            <h5 className="mb-0">
                              {" "}
                              <a
                                href="#/"
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#collapse9"
                                aria-expanded="false"
                                aria-controls="collapse9"
                              >
                                Can I set up automatic bill payments?
                              </a>{" "}
                            </h5>
                          </div>
                          <div
                            id="collapse9"
                            className="collapse"
                            aria-labelledby="heading9"
                            data-parent="#popularTopics2"
                          >
                            <div className="card-body">
                              Yes, you can set up automatic bill payments
                              through our online banking platform. It's a
                              convenient way to ensure your bills are paid on
                              time.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading10">
                            <h5 className="mb-0">
                              {" "}
                              <a
                                href="#/"
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#collapse10"
                                aria-expanded="false"
                                aria-controls="collapse10"
                              >
                                How do I report a lost or stolen debit card?
                              </a>{" "}
                            </h5>
                          </div>
                          <div
                            id="collapse10"
                            className="collapse"
                            aria-labelledby="heading10"
                            data-parent="#popularTopics2"
                          >
                            <div className="card-body">
                              If your debit card is lost or stolen, please
                              contact our 24/7 customer service hotline
                              immediately. We will assist you in securing your
                              account and issuing a replacement card as needed.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-4">
                <a href="#/" className="btn-link text-4">
                  See more topics
                  <i className="fas fa-chevron-right text-2 ml-2" />
                </a>
              </div>
            </div>
          </section>

          <section className="section py-4 my-4 py-sm-5 my-sm-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="bg-white shadow-sm rounded pl-4 pl-sm-0 pr-4 py-4">
                    <div className="row no-gutters">
                      <div className="col-12 col-sm-auto text-13 text-light d-flex align-items-center justify-content-center">
                        <span className="px-4 ml-3 mr-2 mb-4 mb-sm-0">
                          <i className="far fa-envelope" />
                        </span>{" "}
                      </div>
                      <div className="col text-center text-sm-left">
                        <div>
                          <h5 className="text-3 text-body">
                            Can't find what you're looking for?
                          </h5>
                          <p className="text-muted mb-0">
                            We want to answer all of your queries. Get in touch
                            and we'll get back to you as soon as we can.{" "}
                            <Link className="btn-link" to="/contact-us">
                              Contact us
                              <span className="text-1 ml-1">
                                <i className="fas fa-chevron-right" />
                              </span>
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-4 mt-lg-0">
                  <div className="bg-white shadow-sm rounded pl-4 pl-sm-0 pr-4 py-4">
                    <div className="row no-gutters">
                      <div className="col-12 col-sm-auto text-13 text-light d-flex align-items-center justify-content-center">
                        <span className="px-4 ml-3 mr-2 mb-4 mb-sm-0">
                          <i className="far fa-comment-alt" />
                        </span>{" "}
                      </div>
                      <div className="col text-center text-sm-left">
                        <div>
                          <h5 className="text-3 text-body">
                            Technical questions
                          </h5>
                          <p className="text-muted mb-0">
                            Have some technical questions? Hit us up on live
                            chat or whatever.{" "}
                            <a className="btn-link" href="#/">
                              Click here
                              <span className="text-1 ml-1">
                                <i className="fas fa-chevron-right" />
                              </span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Can't find end */}
        </div>

        <Footer />
      </div>

      <BackToTop />
    </>
  );
};

export default Help;
