import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BackToTop from "../components/BackToTop";

const Fees = () => {
  return (
    <>
      <div id="main-wrapper">
        <Header activePage="Fees" />
        <section className="hero-wrap section">
          <div
            className="hero-bg hero-bg-scroll"
            style={{ backgroundImage: 'url("images/bg/image-3.jpg")' }}
          />
          <div className="hero-content">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-7 col-xl-6 text-center text-lg-left">
                  <h1 className="text-11 text-white mb-4">
                    We Promise Low Fees
                    <br />
                    and No Extra Charges
                  </h1>
                  <p className="text-5 text-white line-height-4 mb-4">
                    Signing up to a Pacific Western online banking account is
                    FREE of charge. Create your account today and start!
                  </p>
                  <Link to="/signup" className="btn btn-primary my-2 mr-2">
                    Open a Free Account
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="content">
          <section className="section pt-5 pb-0">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mb-5">
                  <div className="bg-white shadow-sm rounded p-5 text-center">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon text-light border rounded-circle shadow-none">
                        {" "}
                        <i className="fas fa-download" />{" "}
                      </div>
                      <h3 className="text-body text-7 mb-3">
                        Funds Withdrawal
                      </h3>
                      <p className="text-4 line-height-4">
                        You can easily withdraw funds to your local bank account
                        in local currency at excellent rates.
                      </p>
                      <div className="text-primary text-10 pt-3 pb-4 mb-2">
                        up to 1.5%
                      </div>
                      <a href="#/" className="text-muted btn-link text-4">
                        Learn more
                        <i className="fas fa-chevron-right text-2 ml-2" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-5">
                  <div className="bg-white shadow-sm rounded p-5 text-center">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon text-light border rounded-circle shadow-none">
                        {" "}
                        <i className="fas fa-upload" />{" "}
                      </div>
                      <h3 className="text-body text-7 mb-3">Deposit Funds</h3>
                      <p className="text-4 line-height-4">
                        With a wide variety of options to deposit in your
                        account. There is always an option that is right for
                        you.
                      </p>
                      <div className="text-primary text-10 pt-3 pb-4 mb-2">
                        up to 1.0%
                      </div>
                      <a href="#/" className="text-muted btn-link text-4">
                        Learn more
                        <i className="fas fa-chevron-right text-2 ml-2" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-5">
                  <div className="bg-white shadow-sm rounded p-5 text-center">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon text-light border rounded-circle shadow-none">
                        {" "}
                        <i className="fas fa-hand-holding-usd" />{" "}
                      </div>
                      <h3 className="text-body text-7 mb-3">Receive Money</h3>
                      <p className="text-4 line-height-4">
                        Receiving money is always free of charge
                      </p>
                      <div className="text-primary text-10 pt-3 pb-4 mb-2">
                        Free
                      </div>
                      <a href="#/" className="text-muted btn-link text-4">
                        Learn more
                        <i className="fas fa-chevron-right text-2 ml-2" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-5">
                  <div className="bg-white shadow-sm rounded p-5 text-center">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon text-light border rounded-circle shadow-none">
                        {" "}
                        <i className="fas fa-file-invoice-dollar" />{" "}
                      </div>
                      <h3 className="text-body text-7 mb-3">Send Money</h3>
                      <p className="text-4 line-height-4">
                        You can easily make payments at excellent rates.
                      </p>
                      <div className="text-primary text-10 pt-3 pb-4 mb-2">
                        up to 1.0%
                      </div>
                      <a href="#/" className="text-muted btn-link text-4">
                        Learn more
                        <i className="fas fa-chevron-right text-2 ml-2" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-5">
                  <div className="bg-white shadow-sm rounded p-5 text-center">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon text-light border rounded-circle shadow-none">
                        {" "}
                        <i className="fas fa-exchange-alt" />{" "}
                      </div>
                      <h3 className="text-body text-7 mb-3">
                        Currency Conversion
                      </h3>
                      <p className="text-4 line-height-4">
                        We always give you the mid-market rate, which is the
                        fairest exchange rate.{" "}
                      </p>
                      <div className="text-primary text-10 pt-3 pb-4 mb-2">
                        up to 0.4%
                      </div>
                      <a href="#/" className="text-muted btn-link text-4">
                        Learn more
                        <i className="fas fa-chevron-right text-2 ml-2" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-5">
                  <div className="bg-white shadow-sm rounded p-5 text-center">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon text-light border rounded-circle shadow-none">
                        {" "}
                        <i className="fas fa-user" />{" "}
                      </div>
                      <h3 className="text-body text-7 mb-3">
                        Administrative fee
                      </h3>
                      <p className="text-4 line-height-4">
                        Keep using your Pacific Western online banking account
                        and you will never be charged an administrative fee!
                      </p>
                      <div className="text-primary text-10 pt-3 pb-4 mb-2">
                        Free
                      </div>
                      <a href="#/" className="text-muted btn-link text-4">
                        Learn more
                        <i className="fas fa-chevron-right text-2 ml-2" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section bg-primary">
            <div className="container text-center">
              <h2 className="text-9 text-white">
                {" "}
                Open a free account in minutes.
              </h2>
              <p className="text-5 text-white mb-4">
                Quickly and easily send, receive and request money. Over 180
                countries and 80 currencies supported.
              </p>
              <Link to="/signup" className="btn btn-light">
                Open a Free Account
              </Link>
            </div>
          </section>
        </div>
        <Footer />
      </div>

      <BackToTop />
    </>
  );
};

export default Fees;
