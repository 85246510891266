import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BackToTop from "../components/BackToTop";

const NotFound = () => {
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <section className="page-header page-header-text-light bg-secondary py-5 mb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8 order-1 order-md-0">
                <h1>404 - Page not found</h1>
              </div>
              <div className="col-md-4 order-0 order-md-1">
                <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">404 - Page Not Found</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div id="content">
          <section className="section">
            <div className="container text-center">
              <h2 className="text-25 mb-0">404</h2>
              <h3 className="text-6 mb-3">
                Oops! The page you requested was not found!
              </h3>
              <p className="text-3 text-muted">
                The page you are looking for was moved, removed, renamed or
                never existed.
              </p>
              <Link to="/" className="btn btn-primary shadow-none px-5 m-2">
                Home
              </Link>{" "}
            </div>
          </section>
        </div>

        <Footer />
      </div>
      <BackToTop />
    </>
  );
};

export default NotFound;
