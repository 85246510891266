import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDOBv4DyClY89SiNj3IJMzp1KXSN_e0V84",
  authDomain: "rockland-8ab5d.firebaseapp.com",
  projectId: "rockland-8ab5d",
  storageBucket: "rockland-8ab5d.appspot.com",
  messagingSenderId: "109250111703",
  appId: "1:109250111703:web:9a1df7ccbce4e47ac6a892",
};

const app = initializeApp(firebaseConfig);

export default app;
