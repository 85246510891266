import { Link } from "react-router-dom";
import Header from "../components/Header";
import BackToTop from "../components/BackToTop";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <>
      <div id="main-wrapper">
        <Header activePage="Contact" />
        <section className="page-header page-header-text-light bg-dark-3 py-5">
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <ul className="breadcrumb mb-0">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">Contact Us</li>
                </ul>
              </div>
              <div className="col-12">
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </section>
        <div id="content">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="bg-white shadow-md rounded h-100 p-3">
                  <div className="featured-box text-center">
                    <div className="featured-box-icon text-primary mt-4">
                      {" "}
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <h3>Pacific Western</h3>
                    <p>
                      (508) 890-5582,
                      <br />
                      Po Box 422
                      <br />
                      South Fallsburg
                      <br />
                      New York (NY) 12779
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="bg-white shadow-md rounded h-100 p-3">
                  <div className="featured-box text-center">
                    <div className="featured-box-icon text-primary mt-4">
                      {" "}
                      <i className="fas fa-phone" />{" "}
                    </div>
                    <h3>Telephone</h3>
                    <p className="mb-0">(718) 337-7723</p>
                    <p>(718) 479-6002</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="bg-white shadow-md rounded h-100 p-3">
                  <div className="featured-box text-center">
                    <div className="featured-box-icon text-primary mt-4">
                      {" "}
                      <i className="fas fa-envelope" />{" "}
                    </div>
                    <h3>Business Inquiries</h3>
                    <p>
                      <a href="mailto:support@pacwesternonline.com">
                        support@pacwesternonline.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="hero-wrap section shadow-md">
            <div className="hero-mask opacity-9 bg-primary" />
            <div
              className="hero-bg"
              style={{ backgroundImage: 'url("images/bg/image-2.jpg")' }}
            />
            <div className="hero-content">
              <div className="container text-center">
                <h2 className="text-9 text-white">Awesome Customer Support</h2>
                <p className="text-4 text-white mb-4">
                  Have you any query? Don't worry. We have great people ready to
                  help you whenever you need it.
                </p>
                <a href="#/" className="btn btn-light">
                  Find out more
                </a>
              </div>
            </div>
          </section>

          <Footer />
        </div>

        <BackToTop />
      </div>
    </>
  );
};

export default Contact;
