import { FormEventHandler, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const { resetToken } = useParams();

  const resetPassword: FormEventHandler = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      setLoading(true);

      await axios.put(
        `https://api.pacwesternonline.com/api/auth/passwordreset/${resetToken}`,
        { password }
      );

      setLoading(false);
      toast.success("Password reset successfully!");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div id="main-wrapper">
        <div id="content">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
                <div className="bg-white shadow-md rounded p-3 pt-sm-4 pb-sm-5 px-sm-5">
                  <h3 className="font-weight-400 text-center mb-4">
                    Reset Password
                  </h3>
                  <hr className="mx-n4" />
                  <p className="lead text-center">Enter new password</p>
                  <form id="signupForm" onSubmit={resetPassword}>
                    <div className="form-group">
                      <label htmlFor="password">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        required
                        placeholder="Enter new password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setPasswordWeak(false);
                          setPasswordError(false);
                        }}
                      />
                      {passwordWeak && (
                        <p
                          className="text-danger"
                          style={{ fontSize: "15px", marginTop: "8px" }}
                        >
                          Password should be at least 8 characters long
                        </p>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirm-password">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirm-password"
                        required
                        placeholder="Enter new password again"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          setPasswordWeak(false);
                          setPasswordError(false);
                        }}
                      />
                      {passwordError && (
                        <p
                          className="text-danger"
                          style={{ fontSize: "15px", marginTop: "8px" }}
                        >
                          Passwords do not match
                        </p>
                      )}
                    </div>
                    {loading ? (
                      <button
                        className="btn btn-primary btn-block my-4"
                        type="button"
                        disabled
                      >
                        Confirming...
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-block my-4"
                        type="submit"
                      >
                        Confirm
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </>
  );
};

export default ResetPassword;
