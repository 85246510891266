import { useSelector } from "react-redux";
import DashboardHeader from "../../components/DashboardHeader";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import BackToTop from "../../components/BackToTop";
import avatar from "../../assets/avatar.png";

const PaymentMethods = () => {
  const user = useSelector((state: any) => state.user.currentUser);

  return (
    <>
      <div id="main-wrapper">
        <DashboardHeader />
        <div className="bg-primary">
          <div className="container d-flex justify-content-center">
            <ul className="nav secondary-nav">
              <li className="nav-item">
                {" "}
                <Link className="nav-link" to="/profile">
                  Account
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link className="nav-link" to="/security">
                  Security
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link className="nav-link active" to="/payment-methods">
                  Payment Methods
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div id="content" className="py-4">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3">
                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="profile-thumb mt-3 mb-4">
                    {user?.picture ? (
                      <img
                        className="rounded-circle"
                        src={user?.picture}
                        alt="avatar"
                        style={{ width: "100px" }}
                      />
                    ) : (
                      <img
                        className="rounded-circle"
                        src={avatar}
                        alt="avatar"
                        style={{ width: "100px" }}
                      />
                    )}
                  </div>
                  <p className="text-3 font-weight-500 mb-0">
                    Hello, {user.firstName} {user?.middleName} {user.lastName}
                  </p>
                  <p className="text-2 font-weight-400 mb-2">
                    Account ID: {user.accountNumber}
                  </p>
                </div>

                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="text-17 text-light my-3">
                    <i className="fas fa-wallet" />
                  </div>
                  <h3 className="text-9 font-weight-400">
                    ${user.balance?.toLocaleString()}
                  </h3>
                  <p className="mb-2 text-muted opacity-8">Available Balance</p>
                </div>

                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="text-17 text-light my-3">
                    <i className="fas fa-comments" />
                  </div>
                  <h3 className="text-5 font-weight-400 my-4">Need Help?</h3>
                  <p className="text-muted opacity-8 mb-4">
                    Have questions or concerns regarding your account?
                    <br />
                    Our experts are here to help.
                  </p>
                  <a href="#/" className="btn btn-primary btn-block">
                    Chat with Us
                  </a>
                </div>
              </aside>

              <div className="col-lg-9">
                <div className="bg-white shadow-sm rounded p-4 mb-4">
                  <h3 className="text-5 font-weight-400 mb-4">
                    Credit or Debit Cards{" "}
                    <span className="text-muted text-4">(for payments)</span>
                  </h3>
                  <hr className="mb-4 mx-n4" />
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="account-card account-card-primary text-white rounded p-3 mb-4 mb-lg-0">
                        <p className="text-4">XXXX-XXXX-XXXX-4151</p>
                        <p className="d-flex align-items-center">
                          {" "}
                          <span className="account-card-expire text-uppercase d-inline-block opacity-7 mr-2">
                            Valid
                            <br />
                            thru
                            <br />
                          </span>{" "}
                          <span className="text-4 opacity-9">07/24</span>{" "}
                          <span className="badge badge-warning text-0 font-weight-500 rounded-pill px-2 ml-auto">
                            Primary
                          </span>{" "}
                        </p>
                        <p className="d-flex align-items-center m-0">
                          {" "}
                          <span className="text-uppercase font-weight-500">
                            {user?.firstName} {user?.lastName}
                          </span>{" "}
                          <img
                            className="ml-auto"
                            src="images/payment/visa.png"
                            alt="visa"
                          />{" "}
                        </p>
                        <div className="account-card-overlay rounded">
                          <a href="#/" className="text-light btn-link mx-2">
                            <span className="mr-1">
                              <i className="fas fa-minus-circle" />
                            </span>
                            Delete
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="account-card text-white rounded p-3 mb-4 mb-lg-0">
                        <p className="text-4">XXXX-XXXX-XXXX-6296</p>
                        <p className="d-flex align-items-center">
                          {" "}
                          <span className="account-card-expire text-uppercase d-inline-block opacity-7 mr-2">
                            Valid
                            <br />
                            thru
                            <br />
                          </span>{" "}
                          <span className="text-4 opacity-9">07/23</span>{" "}
                        </p>
                        <p className="d-flex align-items-center m-0">
                          {" "}
                          <span className="text-uppercase font-weight-500">
                            {user?.firstName} {user?.lastName}
                          </span>{" "}
                          <img
                            className="ml-auto"
                            src="images/payment/mastercard.png"
                            alt="mastercard"
                          />{" "}
                        </p>
                        <div className="account-card-overlay rounded">
                          <a href="#/" className="text-light btn-link mx-2">
                            <span className="mr-1">
                              <i className="fas fa-minus-circle" />
                            </span>
                            Delete
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      {" "}
                      <a
                        href="#/"
                        data-target="#add-new-card-details"
                        data-toggle="modal"
                        className="account-card-new d-flex align-items-center rounded h-100 p-3 mb-4 mb-lg-0"
                      >
                        <p className="w-100 text-center line-height-4 m-0">
                          {" "}
                          <span className="text-3">
                            <i className="fas fa-plus-circle" />
                          </span>{" "}
                          <span className="d-block text-body text-3">
                            Add New Card
                          </span>{" "}
                        </p>
                      </a>{" "}
                    </div>
                  </div>
                </div>

                {/* Credit or Debit Cards End */}
                {/* Bank Accounts
    ============================================= */}
                <div className="bg-white shadow-sm rounded p-4 mb-4">
                  <h3 className="text-5 font-weight-400 mb-4">
                    Bank Accounts{" "}
                    <span className="text-muted text-4">(for withdrawal)</span>
                  </h3>
                  <hr className="mb-4 mx-n4" />
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      {" "}
                      <a
                        href="#/"
                        data-target="#add-new-bank-account"
                        data-toggle="modal"
                        className="account-card-new d-flex align-items-center rounded h-100 p-3 mb-4 mb-lg-0"
                      >
                        <p className="w-100 text-center line-height-4 m-0">
                          {" "}
                          <span className="text-3">
                            <i className="fas fa-plus-circle" />
                          </span>{" "}
                          <span className="d-block text-body text-3">
                            Add New Bank Account
                          </span>{" "}
                        </p>
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <BackToTop />
    </>
  );
};

export default PaymentMethods;
