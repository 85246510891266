import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Preloader from "../../components/Preloader";
import DashboardHeader from "../../components/DashboardHeader";
import Footer from "../../components/Footer";
import BackToTop from "../../components/BackToTop";
import avatar from "../../assets/avatar.png";
import { Link } from "react-router-dom";
import axios from "axios";

const Dashboard = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState<any>({});

  const user = useSelector((state: any) => state.user.currentUser);

  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const token = localStorage.getItem("pacwest");

    const getTransactions = async () => {
      const { data } = await axios.get(
        `https://api.pacwesternonline.com/api/transaction/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getTransactions();
  }, [user?._id]);

  return (
    <>
      {pageLoading && <Preloader />}
      <div id="main-wrapper">
        <DashboardHeader activePage="Dashboard" />
        <div id="content" className="py-4">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3">
                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="profile-thumb mt-3 mb-4">
                    {user?.picture ? (
                      <img
                        className="rounded-circle"
                        src={user?.picture}
                        alt="avatar"
                        style={{ width: "100px" }}
                      />
                    ) : (
                      <img
                        className="rounded-circle"
                        src={avatar}
                        alt="avatar"
                        style={{ width: "100px" }}
                      />
                    )}
                  </div>
                  <p className="text-3 font-weight-500 mb-0">
                    Hello, {user.firstName} {user?.middleName} {user.lastName}
                  </p>
                  <p className="text-2 font-weight-400 mb-2">
                    Account ID: {user.accountNumber}
                  </p>
                </div>

                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="text-17 text-light my-3">
                    <i className="fas fa-wallet" />
                  </div>
                  <h3 className="text-9 font-weight-400">
                    ${user.balance?.toLocaleString()}
                  </h3>
                  <p className="mb-2 text-muted opacity-8">Available Balance</p>
                </div>

                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="text-17 text-light my-3">
                    <i className="fas fa-comments" />
                  </div>
                  <h3 className="text-5 font-weight-400 my-4">Need Help?</h3>
                  <p className="text-muted opacity-8 mb-4">
                    Have questions or concerns regarding your account?
                    <br />
                    Our experts are here to help.
                  </p>
                  <a href="#/" className="btn btn-primary btn-block">
                    Chat with Us
                  </a>
                </div>
              </aside>

              <div className="col-lg-9">
                <div className="bg-white shadow-sm rounded p-4 mb-4">
                  <h3 className="text-5 font-weight-400 d-flex align-items-center mb-4">
                    Profile Completeness
                    <span className="border text-success rounded-pill font-weight-500 text-2 px-3 py-1 ml-2">
                      50%
                    </span>
                  </h3>
                  <hr className="mb-4 mx-n4" />
                  <div className="row profile-completeness">
                    <div className="col-sm-6 col-md-3 mb-4 mb-md-0">
                      <div className="border rounded text-center px-3 py-4">
                        {" "}
                        <span className="d-block text-10 text-light mt-2 mb-3">
                          <i className="fas fa-mobile-alt" />
                        </span>{" "}
                        <span className="text-5 d-block text-success mt-4 mb-3">
                          <i className="fas fa-check-circle" />
                        </span>
                        <p className="mb-0">Mobile Added</p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3 mb-4 mb-md-0">
                      <div className="border rounded text-center px-3 py-4">
                        {" "}
                        <span className="d-block text-10 text-light mt-2 mb-3">
                          <i className="fas fa-envelope" />
                        </span>{" "}
                        <span className="text-5 d-block text-success mt-4 mb-3">
                          <i className="fas fa-check-circle" />
                        </span>
                        <p className="mb-0">Email Added</p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3 mb-4 mb-sm-0">
                      <div className="border rounded text-center px-3 py-4">
                        {" "}
                        <span className="d-block text-10 text-light mt-2 mb-3">
                          <i className="fas fa-credit-card" />
                        </span>{" "}
                        <span className="text-5 d-block text-light mt-4 mb-3">
                          <i className="far fa-circle " />
                        </span>
                        <p className="mb-0">
                          <a className="btn-link stretched-link" href="#/">
                            Add Card
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="border rounded text-center px-3 py-4">
                        {" "}
                        <span className="d-block text-10 text-light mt-2 mb-3">
                          <i className="fas fa-university" />
                        </span>{" "}
                        <span className="text-5 d-block text-light mt-4 mb-3">
                          <i className="far fa-circle " />
                        </span>
                        <p className="mb-0">
                          <a className="btn-link stretched-link" href="#/">
                            Add Bank Account
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow-sm rounded py-4 mb-4">
                  <h3 className="text-5 font-weight-400 d-flex align-items-center px-4 mb-4">
                    Recent Activity
                  </h3>
                  <div className="transaction-title py-2 px-4">
                    <div className="row font-weight-00">
                      <div className="col-2 col-sm-1 text-center">
                        <span>Date</span>
                      </div>
                      <div className="col col-sm-7">Description</div>
                      <div className="col-auto col-sm-2 d-none d-sm-block text-center">
                        Status
                      </div>
                      <div className="col-3 col-sm-2 text-right">Amount</div>
                    </div>
                  </div>
                  <div className="transaction-list">
                    {transactions?.length > 0 ? (
                      <>
                        {transactions?.slice(0, 3)?.map((transaction: any) => (
                          <div
                            key={transaction._id}
                            className="transaction-item px-4 py-3"
                            data-toggle="modal"
                            data-target="#transaction-detail"
                          >
                            <div
                              className="row align-items-center flex-row"
                              onClick={() =>
                                setSelectedTransaction(transaction)
                              }
                            >
                              <div className="col-2 col-sm-1 text-center">
                                {" "}
                                <span className="d-block text-4 font-weight-300">
                                  {new Date(transaction?.dateTime).getDate()}
                                </span>
                                <span className="d-block text-1 font-weight-300 text-uppercase">
                                  {
                                    month[
                                      new Date(transaction?.dateTime).getMonth()
                                    ]
                                  }
                                </span>
                              </div>
                              <div className="col col-sm-7">
                                {" "}
                                <span className="d-block text-4">
                                  {transaction.description}
                                </span>{" "}
                                {transaction.type === "Deposit" ? (
                                  <span className="text-muted">
                                    Payment Received
                                  </span>
                                ) : (
                                  <span className="text-muted">
                                    Transfer to Bank account
                                  </span>
                                )}
                              </div>
                              <div className="col-auto col-sm-2 d-none d-sm-block text-center text-3">
                                {(transaction.status === "Unconfirmed" ||
                                  transaction.status === "Pending") && (
                                  <span
                                    className="text-warning"
                                    data-toggle="tooltip"
                                    data-original-title="In Progress"
                                  >
                                    <i className="fas fa-ellipsis-h" />
                                  </span>
                                )}
                                {transaction.status === "Completed" && (
                                  <span
                                    className="text-success"
                                    data-toggle="tooltip"
                                    data-original-title="Completed"
                                  >
                                    <i className="fas fa-check-circle" />
                                  </span>
                                )}
                                {transaction.status === "Cancelled" && (
                                  <span
                                    className="text-danger"
                                    data-toggle="tooltip"
                                    data-original-title="Cancelled"
                                  >
                                    <i className="fas fa-times-circle" />
                                  </span>
                                )}
                              </div>
                              <div
                                className="col-3 col-sm-2 text-right text-4"
                                style={{ maxWidth: "100%" }}
                              >
                                {transaction.type === "Deposit" ? (
                                  <span className="text-nowrap">
                                    + ${transaction?.amount?.toLocaleString()}
                                  </span>
                                ) : (
                                  <span className="text-nowrap">
                                    - ${transaction?.amount?.toLocaleString()}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          padding: "20px",
                        }}
                      >
                        No transactions yet
                      </p>
                    )}
                  </div>
                  <div
                    id="transaction-detail"
                    className="modal fade"
                    role="dialog"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered transaction-details"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="row no-gutters">
                            <div className="col-sm-5 d-flex justify-content-center bg-primary rounded-left py-4">
                              <div className="my-auto text-center">
                                <div className="text-8 font-weight-500 text-white my-4">
                                  $
                                  {selectedTransaction?.amount?.toLocaleString()}
                                </div>
                                <p className="text-white">
                                  {new Date(
                                    selectedTransaction?.dateTime
                                  )?.toLocaleDateString()}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-7">
                              <h5 className="text-5 font-weight-400 m-3">
                                Transaction Details
                                <button
                                  type="button"
                                  className="close font-weight-400"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">×</span>{" "}
                                </button>
                              </h5>
                              <hr />
                              <div className="px-3">
                                <ul className="list-unstyled">
                                  <li className="mb-2">
                                    Transaction Type
                                    <span className="float-right text-3">
                                      {selectedTransaction?.type}
                                    </span>
                                  </li>
                                  <li className="mb-2">
                                    Transaction ID
                                    <span className="float-right text-3">
                                      #{selectedTransaction?.transactionId}
                                    </span>
                                  </li>
                                </ul>
                                <hr className="mb-2" />

                                <ul className="list-unstyled">
                                  <li className="font-weight-500">
                                    Description:
                                  </li>
                                  <li className="text-muted">
                                    {selectedTransaction?.description}
                                  </li>
                                </ul>
                                {selectedTransaction?.type === "Withdrawal" && (
                                  <ul className="list-unstyled">
                                    <li className="font-weight-500">
                                      Transfer Option:
                                    </li>
                                    <li className="text-muted">
                                      {selectedTransaction?.transferOption}
                                    </li>
                                  </ul>
                                )}
                                {selectedTransaction?.type === "Withdrawal" && (
                                  <ul className="list-unstyled">
                                    <li className="font-weight-500">
                                      Account Name:
                                    </li>
                                    <li className="text-muted">
                                      {
                                        selectedTransaction?.bankDetails
                                          ?.accountName
                                      }
                                    </li>
                                  </ul>
                                )}
                                {selectedTransaction?.type === "Withdrawal" && (
                                  <ul className="list-unstyled">
                                    <li className="font-weight-500">
                                      Account Number:
                                    </li>
                                    <li className="text-muted">
                                      {
                                        selectedTransaction?.bankDetails
                                          ?.accountNumber
                                      }
                                    </li>
                                  </ul>
                                )}
                                {selectedTransaction?.type === "Withdrawal" && (
                                  <ul className="list-unstyled">
                                    <li className="font-weight-500">
                                      Bank Name:
                                    </li>
                                    <li className="text-muted">
                                      {
                                        selectedTransaction?.bankDetails
                                          ?.bankName
                                      }
                                    </li>
                                  </ul>
                                )}
                                {selectedTransaction?.type === "Withdrawal" && (
                                  <ul className="list-unstyled">
                                    <li className="font-weight-500">
                                      Account Type:
                                    </li>
                                    <li className="text-muted">
                                      {
                                        selectedTransaction?.bankDetails
                                          ?.accountType
                                      }
                                    </li>
                                  </ul>
                                )}
                                {selectedTransaction?.type === "Withdrawal" &&
                                  selectedTransaction?.bankDetails
                                    ?.routingNumber && (
                                    <ul className="list-unstyled">
                                      <li className="font-weight-500">
                                        Routing Number:
                                      </li>
                                      <li className="text-muted">
                                        {
                                          selectedTransaction?.bankDetails
                                            ?.routingNumber
                                        }
                                      </li>
                                    </ul>
                                  )}
                                {selectedTransaction?.type === "Withdrawal" &&
                                  selectedTransaction?.bankDetails?.country && (
                                    <ul className="list-unstyled">
                                      <li className="font-weight-500">
                                        Country:
                                      </li>
                                      <li className="text-muted">
                                        {
                                          selectedTransaction?.bankDetails
                                            ?.country
                                        }
                                      </li>
                                    </ul>
                                  )}
                                {selectedTransaction?.type === "Withdrawal" &&
                                  selectedTransaction?.bankDetails
                                    ?.swiftCode && (
                                    <ul className="list-unstyled">
                                      <li className="font-weight-500">
                                        Swift Code:
                                      </li>
                                      <li className="text-muted">
                                        {
                                          selectedTransaction?.bankDetails
                                            ?.swiftCode
                                        }
                                      </li>
                                    </ul>
                                  )}
                                <ul className="list-unstyled">
                                  <li className="font-weight-500">Status:</li>
                                  {selectedTransaction?.status ===
                                    "Completed" && (
                                    <li className="text-muted">
                                      Completed
                                      <span className="text-success text-3 ml-1">
                                        <i className="fas fa-check-circle" />
                                      </span>
                                    </li>
                                  )}
                                  {selectedTransaction?.status ===
                                    "Cancelled" && (
                                    <li className="text-muted">
                                      Cancelled
                                      <span className="text-danger text-3 ml-1">
                                        <i className="fas fa-times-circle" />
                                      </span>
                                    </li>
                                  )}
                                  {(selectedTransaction?.status === "Pending" ||
                                    selectedTransaction?.status ===
                                      "Unconfirmed") && (
                                    <li className="text-muted">
                                      {selectedTransaction?.status}
                                      <span className="text-warning text-3 ml-1">
                                        <i className="fas fa-ellipsis-h" />
                                      </span>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {transactions?.length > 0 && (
                    <div className="text-center mt-4">
                      <Link to="/transactions" className="btn-link text-3">
                        View all
                        <i className="fas fa-chevron-right text-2 ml-2" />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <BackToTop />
    </>
  );
};

export default Dashboard;
