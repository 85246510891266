import { useSelector } from "react-redux";
import DashboardHeader from "../../components/DashboardHeader";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import BackToTop from "../../components/BackToTop";
import avatar from "../../assets/avatar.png";

const Security = () => {
  const user = useSelector((state: any) => state.user.currentUser);

  return (
    <>
      <div id="main-wrapper">
        <DashboardHeader />
        <div className="bg-primary">
          <div className="container d-flex justify-content-center">
            <ul className="nav secondary-nav">
              <li className="nav-item">
                {" "}
                <Link className="nav-link" to="/profile">
                  Account
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link className="nav-link active" to="/security">
                  Security
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link className="nav-link" to="/payment-methods">
                  Payment Methods
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div id="content" className="py-4">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3">
                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="profile-thumb mt-3 mb-4">
                    {user?.picture ? (
                      <img
                        className="rounded-circle"
                        src={user?.picture}
                        alt="avatar"
                        style={{ width: "100px" }}
                      />
                    ) : (
                      <img
                        className="rounded-circle"
                        src={avatar}
                        alt="avatar"
                        style={{ width: "100px" }}
                      />
                    )}
                  </div>
                  <p className="text-3 font-weight-500 mb-0">
                    Hello, {user.firstName} {user?.middleName} {user.lastName}
                  </p>
                  <p className="text-2 font-weight-400 mb-2">
                    Account ID: {user.accountNumber}
                  </p>
                </div>

                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="text-17 text-light my-3">
                    <i className="fas fa-wallet" />
                  </div>
                  <h3 className="text-9 font-weight-400">
                    ${user.balance?.toLocaleString()}
                  </h3>
                  <p className="mb-2 text-muted opacity-8">Available Balance</p>
                </div>

                <div className="bg-white shadow-sm rounded text-center p-3 mb-4">
                  <div className="text-17 text-light my-3">
                    <i className="fas fa-comments" />
                  </div>
                  <h3 className="text-5 font-weight-400 my-4">Need Help?</h3>
                  <p className="text-muted opacity-8 mb-4">
                    Have questions or concerns regarding your account?
                    <br />
                    Our experts are here to help.
                  </p>
                  <a href="#/" className="btn btn-primary btn-block">
                    Chat with Us
                  </a>
                </div>
              </aside>

              <div className="col-lg-9">
                <div className="bg-white shadow-sm rounded p-4 mb-4">
                  <h3 className="text-5 font-weight-400 d-flex align-items-center mb-4">
                    Security Questions
                    <a
                      href="#change-security-questions"
                      data-toggle="modal"
                      className="ml-auto text-2 text-uppercase btn-link"
                    >
                      <span className="mr-1">
                        <i className="fas fa-edit" />
                      </span>
                      Change
                    </a>
                  </h3>
                  <hr className="mx-n4 mb-4" />
                  <p className="text-3">
                    For your protection, please choose 3 security questions.
                    This way, we can verify it’s really you if there’s ever a
                    doubt.
                  </p>
                </div>

                <div
                  id="change-security-questions"
                  className="modal fade "
                  role="dialog"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title font-weight-400">
                          Security Questions
                        </h5>
                        <button
                          type="button"
                          className="close font-weight-400"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          {" "}
                          <span aria-hidden="true">×</span>{" "}
                        </button>
                      </div>
                      <div className="modal-body p-4">
                        <p className="text-3 mb-4">
                          We'll use these questions as a way to make sure it's
                          your account, like if you need to reset your password
                        </p>
                        <form
                          id="changePassword"
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <div className="form-group">
                            <label htmlFor="question1">
                              Security Question 1
                            </label>
                            <select
                              className="custom-select"
                              id="question1"
                              name="question1"
                            >
                              <option value="">Select a question</option>
                              <option value={1}>
                                What was your childhood nickname?
                              </option>
                              <option value={2}>
                                {" "}
                                What is the name of your favorite childhood
                                friend?
                              </option>
                              <option value={3}>
                                {" "}
                                What was the name of your first stuffed animal?
                              </option>
                              <option value={4}>
                                {" "}
                                In what city or town did your mother and father
                                meet?
                              </option>
                              <option value={5}>
                                What is your youngest brother’s birthday year?
                              </option>
                              <option value={6}>
                                {" "}
                                What is your maternal grandmother's maiden name?
                              </option>
                              <option value={7}>
                                {" "}
                                In what city or town was your first job?
                              </option>
                              <option value={8}>
                                {" "}
                                What is the name of a college you applied to?
                              </option>
                            </select>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              data-bv-field="answers1"
                              id="answers1"
                              placeholder="Answer"
                            />
                          </div>
                          <div className="form-group mt-4">
                            <label htmlFor="question2">
                              Security Question 2
                            </label>
                            <select
                              className="custom-select"
                              id="question2"
                              name="question2"
                            >
                              <option value="">Select a question</option>
                              <option value={1}>
                                What was your childhood nickname?
                              </option>
                              <option value={2}>
                                {" "}
                                What is the name of your favorite childhood
                                friend?
                              </option>
                              <option value={3}>
                                {" "}
                                What was the name of your first stuffed animal?
                              </option>
                              <option value={4}>
                                {" "}
                                In what city or town did your mother and father
                                meet?
                              </option>
                              <option value={5}>
                                What is your youngest brother’s birthday year?
                              </option>
                              <option value={6}>
                                {" "}
                                What is your maternal grandmother's maiden name?
                              </option>
                              <option value={7}>
                                {" "}
                                In what city or town was your first job?
                              </option>
                              <option value={8}>
                                {" "}
                                What is the name of a college you applied to?
                              </option>
                            </select>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              data-bv-field="answers2"
                              id="answers2"
                              placeholder="Answer"
                            />
                          </div>
                          <div className="form-group mt-4">
                            <label htmlFor="question3">
                              Security Question 3
                            </label>
                            <select
                              className="custom-select"
                              id="question3"
                              name="question3"
                            >
                              <option value="">Select a question</option>
                              <option value={1}>
                                What was your childhood nickname?
                              </option>
                              <option value={2}>
                                {" "}
                                What is the name of your favorite childhood
                                friend?
                              </option>
                              <option value={3}>
                                {" "}
                                What was the name of your first stuffed animal?
                              </option>
                              <option value={4}>
                                {" "}
                                In what city or town did your mother and father
                                meet?
                              </option>
                              <option value={5}>
                                What is your youngest brother’s birthday year?
                              </option>
                              <option value={6}>
                                {" "}
                                What is your maternal grandmother's maiden name?
                              </option>
                              <option value={7}>
                                {" "}
                                In what city or town was your first job?
                              </option>
                              <option value={8}>
                                {" "}
                                What is the name of a college you applied to?
                              </option>
                            </select>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              data-bv-field="answers3"
                              id="answers3"
                              placeholder="Answer"
                            />
                          </div>
                          <button
                            className="btn btn-primary btn-block mt-4"
                            type="submit"
                          >
                            Save Changes
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow-sm rounded p-4 mb-4">
                  <h3 className="text-5 font-weight-400 d-flex align-items-center mb-4">
                    2-Step Verification
                    <a
                      href="#two-step-verification"
                      data-toggle="modal"
                      className="ml-auto text-2 text-uppercase btn-link"
                    >
                      <span className="mr-1">
                        <i className="fas fa-tools" />
                      </span>
                      SetUp
                    </a>
                  </h3>
                  <hr className="mx-n4 mb-4" />
                  <p className="text-3">
                    Add an extra layer of security to your account by using a
                    one-time security code in addition to your password each
                    time you log in.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <BackToTop />
    </>
  );
};

export default Security;
