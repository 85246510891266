import { useState, FormEventHandler } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/redux/userRedux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BackToTop from "../../components/BackToTop";

const Login = () => {
  const [input, setInput] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      if (type === "email") {
        const { data } = await axios.post(
          "https://api.pacwesternonline.com/api/auth/login",
          {
            email: input?.toLowerCase(),
            password,
          }
        );

        setLoading(false);
        dispatch(loginUser(data));
        localStorage.setItem("pacwest", data.token);
        navigate("/dashboard");
      } else {
        const { data } = await axios.post(
          "https://api.pacwesternonline.com/api/auth/login",
          {
            accountNumber: input,
            password,
          }
        );

        setLoading(false);
        dispatch(loginUser(data));
        localStorage.setItem("pacwest", data.token);
        navigate("/dashboard");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.error === "Account disabled") {
          toast.error("Account disabled. Please contact support.");
        } else {
          toast.error(error.response.data.error);
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <div id="content">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
                <div className="bg-white shadow-md rounded p-3 pt-sm-4 pb-sm-5 px-sm-5">
                  <h3 className="font-weight-400 text-center mb-4">Sign In</h3>
                  <hr className="mx-n5" />
                  <p className="lead text-center">
                    We are glad to see you again!
                  </p>
                  <form id="loginForm" onSubmit={loginHandler}>
                    <div className="form-group">
                      <label htmlFor="emailAddress">
                        Email Address or Account Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailAddress"
                        required
                        placeholder="Enter Your Email or Account number"
                        value={input}
                        onChange={(e) => {
                          setInput(e.target.value);
                          const value = e.target.value;
                          if (!isNaN(+value)) {
                            setType("accountNumber");
                          } else {
                            setType("email");
                          }
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="loginPassword">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="loginPassword"
                        required
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="row">
                      <div className="col-sm text-right">
                        <Link className="btn-link" to="/forgot-password">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    {loading ? (
                      <button
                        className="btn btn-primary btn-block my-4"
                        type="button"
                        disabled
                      >
                        Signing In...
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-block my-4"
                        type="submit"
                      >
                        Sign In
                      </button>
                    )}
                  </form>
                  <p className="text-3 text-muted text-center mb-0">
                    Don't have an account?{" "}
                    <Link className="btn-link" to="/signup">
                      Sign Up
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <BackToTop />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </>
  );
};

export default Login;
