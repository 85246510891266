import { useState, FormEventHandler } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const forgotPasswordHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(
        "https://api.pacwesternonline.com/api/auth/forgotpassword",
        {
          email: email.toLowerCase(),
        }
      );

      if (data === "Email sent") {
        setLoading(false);
        setEmail("");
        toast.success("Email sent!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div id="main-wrapper">
        <div id="content">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
                <div className="bg-white shadow-md rounded p-3 pt-sm-4 pb-sm-5 px-sm-5">
                  <h3 className="font-weight-400 text-center mb-4">
                    Forgot Password?
                  </h3>
                  <hr className="mx-n4" />
                  <p className="lead text-center">
                    Enter your email address and we'll send you details on how
                    to reset your password
                  </p>
                  <form id="signupForm" onSubmit={forgotPasswordHandler}>
                    <div className="form-group">
                      <label htmlFor="email">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        required
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    {loading ? (
                      <button
                        className="btn btn-primary btn-block my-4"
                        type="button"
                        disabled
                      >
                        Confirming...
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-block my-4"
                        type="submit"
                      >
                        Confirm
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </>
  );
};

export default ForgotPassword;
