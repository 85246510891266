import BackToTop from "../components/BackToTop";
import Footer from "../components/Footer";
import Header from "../components/Header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div id="main-wrapper">
        <Header activePage="Home" />
        <div id="content">
          <OwlCarousel
            className="owl-carousel owl-theme single-slideshow"
            autoplay
            loop
            nav
            items={1}
          >
            <div className="item">
              <section className="hero-wrap">
                <div className="hero-mask opacity-7 bg-dark" />
                <div
                  className="hero-bg"
                  style={{ backgroundImage: 'url("images/bg/image-1.jpg")' }}
                />
                <div className="hero-content d-flex fullscreen-with-header py-5">
                  <div className="container my-auto text-center">
                    <h2 className="text-16 text-white">
                      Send &amp; Receive Money
                    </h2>
                    <p className="text-5 text-white mb-4">
                      Quickly and easily send, receive and request money online
                      <br className="d-none d-lg-block" />
                      Over 180 countries and 120 currencies supported.
                    </p>
                    <Link to="/signup" className="btn btn-primary m-2">
                      Open a Free Account
                    </Link>{" "}
                  </div>
                </div>
              </section>
            </div>
            <div className="item">
              <section className="hero-wrap">
                <div
                  className="hero-bg"
                  style={{ backgroundImage: 'url("images/bg/image-3.jpg")' }}
                />
                <div className="hero-content d-flex fullscreen-with-header py-5">
                  <div className="container my-auto">
                    <div className="row">
                      <div className="col-12 col-lg-8 col-xl-7 text-center text-lg-left">
                        <h2 className="text-13 text-white">
                          Trusted by more than 50,000 businesses worldwide
                        </h2>
                        <p className="text-5 text-white mb-4">
                          Over 180 countries and 120 currencies supported
                        </p>
                        <Link to="/signup" className="btn btn-primary mr-3">
                          Get started for free
                        </Link>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </OwlCarousel>

          <section className="section bg-white">
            <div className="container">
              <h2 className="text-9 text-center">Why should you choose us?</h2>
              <p className="lead text-center mb-5">
                Here’s Top 4 reasons why using a Pacific Western online banking
                account for managing your money makes sense
              </p>
              <div className="row">
                <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                  <div className="featured-box">
                    <div className="featured-box-icon text-primary">
                      {" "}
                      <i className="fas fa-hand-pointer" />{" "}
                    </div>
                    <h3>Easy to use</h3>
                    <p className="text-3">
                      Experience banking made easy. Our intuitive platform
                      ensures a smooth and effortless journey for all your
                      financial needs.
                    </p>
                    <a href="#/" className="btn-link text-3">
                      Learn more
                      <i className="fas fa-chevron-right text-1 ml-2" />
                    </a>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                  <div className="featured-box">
                    <div className="featured-box-icon text-primary">
                      {" "}
                      <i className="fas fa-share" />{" "}
                    </div>
                    <h3>Faster Payments</h3>
                    <p className="text-3">
                      Say goodbye to waiting as you enjoy lightning-fast payment
                      processing for swift and efficient transactions. Your time
                      is precious, and we value it.
                    </p>
                    <a href="#/" className="btn-link text-3">
                      Learn more
                      <i className="fas fa-chevron-right text-1 ml-2" />
                    </a>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-5 mb-sm-0">
                  <div className="featured-box">
                    <div className="featured-box-icon text-primary">
                      {" "}
                      <i className="fas fa-dollar-sign" />{" "}
                    </div>
                    <h3>Lower Fees</h3>
                    <p className="text-3">
                      Keep more of your money where it belongs—with you. We
                      offer competitive rates and lower fees, so you can
                      maximize your savings and enjoy banking without the extra
                      costs.
                    </p>
                    <a href="#/" className="btn-link text-3">
                      Learn more
                      <i className="fas fa-chevron-right text-1 ml-2" />
                    </a>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="featured-box">
                    <div className="featured-box-icon text-primary">
                      {" "}
                      <i className="fas fa-lock" />{" "}
                    </div>
                    <h3>100% secure</h3>
                    <p className="text-3">
                      Your peace of mind is our top priority. We employ
                      state-of-the-art security measures to ensure that your
                      financial information remains completely safe.
                    </p>
                    <a href="#/" className="btn-link text-3">
                      Learn more
                      <i className="fas fa-chevron-right text-1 ml-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="container overflow-hidden">
              <div className="row">
                <div className="col-lg-5 col-xl-6 d-flex">
                  <div className="my-auto pb-5 pb-lg-0">
                    <h2 className="text-9">Payment Solutions for anyone</h2>
                    <p className="text-4">
                      No matter your financial situation, Pacific Western online
                      banking has payment solutions tailored to your needs. From
                      basic banking to specialized services, we're here to help
                      everyone manage their payments seamlessly.
                    </p>
                    <a href="#/" className="btn-link text-4">
                      Find more solution
                      <i className="fas fa-chevron-right text-2 ml-2" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-7 col-xl-6">
                  <div className="row banner style-2 justify-content-center">
                    <div className="col-12 col-sm-6 mb-4 text-center">
                      <div className="item rounded shadow d-inline-block">
                        {" "}
                        <a href="#/">
                          <div className="caption rounded-bottom">
                            <h2 className="text-5 font-weight-400 mb-0">
                              Freelancer
                            </h2>
                          </div>
                          <div className="banner-mask" />
                          <img
                            className="img-fluid"
                            src="images/anyone-freelancer.jpg"
                            width={410}
                            height={350}
                            alt="banner"
                          />
                        </a>{" "}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-4 text-center">
                      <div className="item rounded shadow d-inline-block">
                        {" "}
                        <a href="#/">
                          <div className="caption rounded-bottom">
                            <h2 className="text-5 font-weight-400 mb-0">
                              Online Shopping
                            </h2>
                          </div>
                          <div className="banner-mask" />
                          <img
                            className="img-fluid"
                            src="images/anyone-online-shopping.jpg"
                            width={410}
                            height={350}
                            alt="banner"
                          />
                        </a>{" "}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-4 mb-sm-0 text-center">
                      <div className="item rounded shadow d-inline-block">
                        {" "}
                        <a href="#/">
                          <div className="caption rounded-bottom">
                            <h2 className="text-5 font-weight-400 mb-0">
                              Online Sellers
                            </h2>
                          </div>
                          <div className="banner-mask" />
                          <img
                            className="img-fluid"
                            src="images/anyone-online-sellers.jpg"
                            width={410}
                            height={350}
                            alt="banner"
                          />
                        </a>{" "}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 text-center">
                      <div className="item rounded shadow d-inline-block">
                        {" "}
                        <a href="#/">
                          <div className="caption rounded-bottom">
                            <h2 className="text-5 font-weight-400 mb-0">
                              Affiliate Marketing
                            </h2>
                          </div>
                          <div className="banner-mask" />
                          <img
                            className="img-fluid"
                            src="images/anyone-affiliate-marketing.jpg"
                            width={410}
                            height={350}
                            alt="banner"
                          />
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section bg-white">
            <div className="container">
              <h2 className="text-9 text-center">What can you do?</h2>
              <p className="lead text-center mb-5">
                Here are some of the services we offer
              </p>
              <div className="row">
                <div className="col-sm-6 col-lg-3 mb-4">
                  {" "}
                  <a href="#/">
                    <div className="featured-box style-5 rounded">
                      <div className="featured-box-icon text-primary">
                        {" "}
                        <i className="fas fa-share-square" />{" "}
                      </div>
                      <h3>Send Money</h3>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                  {" "}
                  <a href="#/">
                    <div className="featured-box style-5 rounded">
                      <div className="featured-box-icon text-primary">
                        {" "}
                        <i className="fas fa-check-square" />{" "}
                      </div>
                      <h3>Receive Money</h3>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                  {" "}
                  <a href="#/">
                    <div className="featured-box style-5 rounded">
                      <div className="featured-box-icon text-primary">
                        {" "}
                        <i className="fas fa-user-friends" />{" "}
                      </div>
                      <h3>Pay a Friend</h3>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                  {" "}
                  <a href="#/">
                    <div className="featured-box style-5 rounded">
                      <div className="featured-box-icon text-primary">
                        {" "}
                        <i className="fas fa-shopping-bag" />{" "}
                      </div>
                      <h3>Online Shopping</h3>
                    </div>
                  </a>{" "}
                </div>
              </div>
              <div className="text-center mt-4">
                <a href="#/" className="btn-link text-4">
                  See more can you do
                  <i className="fas fa-chevron-right text-2 ml-2" />
                </a>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card bg-dark-3 shadow-sm border-0">
                    {" "}
                    <img
                      className="card-img opacity-8"
                      src="images/how-work.jpg"
                      width={570}
                      height={362}
                      alt="banner"
                    />
                    <div className="card-img-overlay p-0">
                      {" "}
                      <a className="d-flex h-100 video-btn" href="#/">
                        <span className="btn-video-play bg-white shadow-md rounded-circle m-auto">
                          <i className="fas fa-play" />
                        </span>{" "}
                      </a>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-5 mt-lg-0">
                  <div className="ml-4">
                    <h2 className="text-9">How does it work?</h2>
                    <ul className="list-unstyled text-3 line-height-5">
                      <li>
                        <i className="fas fa-check mr-2" />
                        Sign Up Account
                      </li>
                      <li>
                        <i className="fas fa-check mr-2" />
                        Receive &amp; Send Payments from worldwide
                      </li>
                      <li>
                        <i className="fas fa-check mr-2" />
                        Your funds will be transferred to your local bank
                        account
                      </li>
                    </ul>
                    <Link
                      to="/signup"
                      className="btn btn-outline-primary shadow-none mt-2"
                    >
                      Open a Free Account
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="container">
              <h2 className="text-9 text-center">
                What people are saying about us
              </h2>
              <p className="lead text-center mb-4">
                A payments experience people love to talk about
              </p>
              <OwlCarousel
                className="owl-carousel owl-theme"
                autoplay
                nav
                loop
                margin={30}
                slideBy={2}
                stagePadding={5}
                items={1}
              >
                <div className="item">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “Switching to Pacific Western online banking was the best
                      decision I made for my business. Their online banking
                      services are incredibly user-friendly, and their support
                      team is always ready to assist. Thanks to them, managing
                      my finances has become a breeze.”
                    </p>
                    <strong className="d-block font-weight-500">
                      Jay Shah
                    </strong>{" "}
                    <span className="text-muted">
                      Founder at Icomatic Pvt Ltd
                    </span>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “As a college student, managing money can be challenging.
                      Pacific Western online has been a lifesaver. I can check
                      my balances, pay bills, and even save effortlessly. It's
                      the perfect bank for my generation.”
                    </p>
                    <strong className="d-block font-weight-500">
                      Patrick .M. Cary
                    </strong>{" "}
                    <span className="text-muted">Freelancer from USA</span>
                  </div>
                </div>
                <div className="item mh-100">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “Fast easy to use transfers to a different currency. Much
                      better value that the banks.”
                    </p>
                    <strong className="d-block font-weight-500">
                      De Mortel
                    </strong>{" "}
                    <span className="text-muted">Online Retail</span>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “I have used them twice now. Good rates, very efficient
                      service and it denies high street banks an undeserved
                      windfall. Excellent.”
                    </p>
                    <strong className="d-block font-weight-500">
                      Chris Tom
                    </strong>{" "}
                    <span className="text-muted">User from UK</span>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “It's a real good idea to manage your money by Pacific
                      Western. The rates are fair and you can carry out the
                      transactions without worrying!”
                    </p>
                    <strong className="d-block font-weight-500">
                      Mauri Lindberg
                    </strong>{" "}
                    <span className="text-muted">
                      Freelancer from Australia
                    </span>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial rounded text-center p-4">
                    <p className="text-4">
                      “Only trying it out since a few days. But up to now
                      excellent. Seems to work flawlessly. I'm only using it for
                      sending money to friends at the moment.”
                    </p>
                    <strong className="d-block font-weight-500">
                      Dennis Jacques
                    </strong>{" "}
                    <span className="text-muted">User from USA</span>
                  </div>
                </div>
              </OwlCarousel>

              <div className="text-center mt-4">
                <a href="#/" className="btn-link text-4">
                  See more reviews
                  <i className="fas fa-chevron-right text-2 ml-2" />
                </a>
              </div>
            </div>
          </section>

          <section className="hero-wrap section shadow-md">
            <div className="hero-mask opacity-9 bg-primary" />
            <div
              className="hero-bg"
              style={{ backgroundImage: 'url("images/bg/image-2.jpg")' }}
            />
            <div className="hero-content py-5">
              <div className="container text-center">
                <h2 className="text-9 text-white">Awesome Customer Support</h2>
                <p className="lead text-white mb-4">
                  Have you any query? Don't worry. We have great people ready to
                  help you whenever you need it.
                </p>
                <a href="#/" className="btn btn-light">
                  Find out more
                </a>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
      <BackToTop />
    </>
  );
};

export default Home;
