import { useState, FormEventHandler } from "react";
import { Link, useNavigate } from "react-router-dom";
import BackToTop from "../../components/BackToTop";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [registerFormData, setRegisterFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
  });

  const navigate = useNavigate();

  function getRandom(length: number) {
    return Math.floor(
      Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
    );
  }

  const inputChange = (e: any) => {
    setRegisterFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const registerHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    if (registerFormData.password.length < 8) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (registerFormData.password !== registerFormData.confirmPassword) {
      setRegisterFormData((prevState) => ({
        ...prevState,
        password: "",
        confirmPassword: "",
      }));
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://api.pacwesternonline.com/api/auth/register",
        {
          firstName: registerFormData.firstName,
          middleName: registerFormData.middleName,
          lastName: registerFormData.lastName,
          email: registerFormData.email?.toLowerCase(),
          password: registerFormData.password,
          phone: registerFormData.phone,
          accountNumber: `9${getRandom(11)}`,
        }
      );

      if (data.message === "Verification email sent") {
        setLoading(false);
        navigate("/otp-verification");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <div id="content">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
                <div className="bg-white shadow-md rounded p-3 pt-sm-4 pb-sm-5 px-sm-5">
                  <h3 className="font-weight-400 text-center mb-4">Sign Up</h3>
                  <hr className="mx-n4" />
                  <p className="lead text-center">
                    Your information is safe with us
                  </p>
                  <form id="signupForm" onSubmit={registerHandler}>
                    <div className="form-group">
                      <label htmlFor="firstName">First Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        required
                        placeholder="Enter Your First Name"
                        name="firstName"
                        value={registerFormData.firstName}
                        onChange={(e) => {
                          inputChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="middleName">Middle Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="middleName"
                        placeholder="Enter Your Middle Name"
                        name="middleName"
                        value={registerFormData.middleName}
                        onChange={(e) => {
                          inputChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        required
                        placeholder="Enter Your Last Name"
                        name="lastName"
                        value={registerFormData.lastName}
                        onChange={(e) => {
                          inputChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="emailAddress">Email Address *</label>
                      <input
                        type="email"
                        className="form-control"
                        id="emailAddress"
                        required
                        placeholder="Enter Your Email"
                        name="email"
                        value={registerFormData.email}
                        onChange={(e) => {
                          inputChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Phone Number *</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        required
                        placeholder="Enter Your Phone number"
                        name="phone"
                        value={registerFormData.phone}
                        onChange={(e) => {
                          inputChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password *</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        required
                        name="password"
                        placeholder="Enter Password"
                        value={registerFormData.password}
                        onChange={(e) => {
                          inputChange(e);
                          setPasswordError(false);
                          setPasswordWeak(false);
                        }}
                      />
                      {passwordWeak && (
                        <p
                          className="text-danger"
                          style={{ fontSize: "15px", marginTop: "8px" }}
                        >
                          Password should be at least 8 characters long
                        </p>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirmPassword">
                        Confirm Password *
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        required
                        name="confirmPassword"
                        placeholder="Enter Password again"
                        value={registerFormData.confirmPassword}
                        onChange={(e) => {
                          inputChange(e);
                          setPasswordError(false);
                          setPasswordWeak(false);
                        }}
                      />
                      {passwordError && (
                        <p
                          className="text-danger"
                          style={{ fontSize: "15px", marginTop: "8px" }}
                        >
                          Passwords do not match
                        </p>
                      )}
                    </div>
                    {loading ? (
                      <button
                        className="btn btn-primary btn-block my-4"
                        type="button"
                        disabled
                      >
                        Signing Up...
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-block my-4"
                        type="submit"
                      >
                        Sign Up
                      </button>
                    )}
                  </form>
                  <p className="text-3 text-muted text-center mb-0">
                    Already have an account?{" "}
                    <Link className="btn-link" to="/login">
                      Log In
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <BackToTop />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </>
  );
};

export default Signup;
