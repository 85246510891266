import { FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OtpVerification = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const verify: FormEventHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post("https://api.pacwesternonline.com/api/auth/verify", {
        otp: +code,
      });

      setLoading(false);
      toast.success("Account Verified!");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <div id="main-wrapper">
        <div id="content">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
                <div className="bg-white shadow-md rounded p-3 pt-sm-4 pb-sm-5 px-sm-5">
                  <h3 className="font-weight-400 text-center mb-4">
                    OTP Verification
                  </h3>
                  <hr className="mx-n4" />
                  <p className="lead text-center">
                    We sent an OTP to your email address. Please input the code
                    below to complete your registration.
                  </p>
                  <form id="signupForm" onSubmit={verify}>
                    <div className="form-group">
                      <label htmlFor="otp">OTP</label>
                      <input
                        type="number"
                        className="form-control"
                        id="otp"
                        required
                        placeholder="123456"
                        name="otp"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div>

                    {loading ? (
                      <button
                        className="btn btn-primary btn-block my-4"
                        type="button"
                        disabled
                      >
                        Confirming...
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-block my-4"
                        type="submit"
                      >
                        Confirm
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </>
  );
};

export default OtpVerification;
