import { useState, FormEventHandler } from "react";
import { useSelector } from "react-redux";
import DashboardHeader from "../../components/DashboardHeader";
import Footer from "../../components/Footer";
import BackToTop from "../../components/BackToTop";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "../../components/Preloader";
import { Link, useNavigate } from "react-router-dom";

const SendMoney = () => {
  const [tabActive, setTabActive] = useState("Local");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bankName, setBankName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [stepOneCompleted, setStepOneCompleted] = useState(false);
  const [stepTwoCompleted, setStepTwoCompleted] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [code, setCode] = useState("");
  const [transferCompleted, setTransferCompleted] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [routingNumber, setRoutingNumber] = useState("");
  const [country, setCountry] = useState("");
  const [swiftCode, setSwiftCode] = useState("");

  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();

  const sendLocalOtp = async () => {
    setPageLoading(true);
    const token = localStorage.getItem("pacwest");

    try {
      const { data } = await axios.post(
        `https://api.pacwesternonline.com/api/transaction/initiate/${user?._id}`,
        {
          user,
          amount,
          description,
          email: user?.email,
          transactionId: new Date().getTime().toString(),
          transferOption: "Local",
          dateTime: new Date(),
          bankDetails: {
            accountName,
            bankName,
            accountNumber,
            accountType,
          },
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      if (data.message === "Verification email sent") {
        setPageLoading(false);
        setOtpModalOpen(true);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      setPageLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const sendInternationalOtp = async () => {
    setPageLoading(true);
    const token = localStorage.getItem("pacwest");

    try {
      const { data } = await axios.post(
        `https://api.pacwesternonline.com/api/transaction/initiate/${user?._id}`,
        {
          user,
          amount,
          description,
          email: user?.email,
          transactionId: new Date().getTime().toString(),
          transferOption: "International",
          dateTime: new Date(),
          bankDetails: {
            accountName,
            accountNumber,
            bankName,
            routingNumber,
            country,
            swiftCode,
            accountType,
          },
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      if (data.message === "Verification email sent") {
        setPageLoading(false);
        setOtpModalOpen(true);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      setPageLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const checkOtp: FormEventHandler = async (e) => {
    e.preventDefault();

    setPageLoading(true);
    const token = localStorage.getItem("pacwest");

    try {
      await axios.post(
        `https://api.pacwesternonline.com/api/transaction/verify/${user?._id}`,
        { otp: +code },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setPageLoading(false);
      setOtpModalOpen(false);
      setTransferCompleted(true);
    } catch (error: any) {
      setPageLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      {pageLoading && <Preloader />}
      <div id="main-wrapper">
        <DashboardHeader activePage="Send" />
        {!user?.transferDisabled && (
          <div className="bg-white">
            <div className="container d-flex justify-content-center">
              <ul className="nav secondary-nav alternate">
                <li className="nav-item">
                  <a
                    className={`${
                      tabActive === "Local" ? "nav-link active" : "nav-link"
                    }`}
                    href="#/"
                    onClick={() => setTabActive("Local")}
                  >
                    Local
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`${
                      tabActive === "International"
                        ? "nav-link active"
                        : "nav-link"
                    }`}
                    href="#/"
                    onClick={() => setTabActive("International")}
                  >
                    International
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        {user?.transferDisabled ? (
          <div className="row" style={{ marginTop: "24px" }}>
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
              <div className="bg-white shadow-sm rounded p-3 pt-sm-5 pb-sm-5 px-sm-5 mb-4">
                <form className="row g-4">
                  <div className="col-12">
                    <div className="row">
                      <img
                        src="/images/error.png"
                        alt="error"
                        style={{ width: "60%", margin: "auto" }}
                      />
                      <p
                        style={{
                          margin: "auto",
                          paddingTop: "12px",
                        }}
                      >
                        Account on hold. Please contact support
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div id="content" className="py-4">
            <div className="container">
              {tabActive === "Local" && (
                <>
                  <div className="row mt-4 mb-5">
                    <div className="col-lg-11 mx-auto">
                      <div className="row widget-steps">
                        {!stepOneCompleted && !transferCompleted && (
                          <div className="col-4 step active">
                            <div className="step-name">Details</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                        {stepOneCompleted && (
                          <div className="col-4 step complete">
                            <div className="step-name">Details</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                        {!stepOneCompleted && !transferCompleted && (
                          <div className="col-4 step disabled">
                            <div className="step-name">Confirm</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                        {stepOneCompleted &&
                          !stepTwoCompleted &&
                          !transferCompleted && (
                            <div className="col-4 step active">
                              <div className="step-name">Confirm</div>
                              <div className="progress">
                                <div className="progress-bar" />
                              </div>
                              <a href="#/" className="step-dot">
                                {" "}
                              </a>
                            </div>
                          )}
                        {stepOneCompleted && stepTwoCompleted && (
                          <div className="col-4 step complete">
                            <div className="step-name">Confirm</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                        {!transferCompleted && (
                          <div className="col-4 step disabled">
                            <div className="step-name">Success</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                        {transferCompleted && (
                          <div className="col-4 step complete">
                            <div className="step-name">Success</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <h2 className="font-weight-400 text-center mt-3 mb-4">
                    Send money locally
                  </h2>
                  {!stepOneCompleted && !transferCompleted && (
                    <div className="row">
                      <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
                        <div className="bg-white shadow-sm rounded p-3 pt-sm-5 pb-sm-5 px-sm-5 mb-4">
                          <form
                            id="form-send-money"
                            onSubmit={(e) => {
                              e.preventDefault();
                              setStepOneCompleted(true);
                            }}
                          >
                            <div className="form-group">
                              <label htmlFor="youSend">Amount</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  className="form-control"
                                  id="youSend"
                                  placeholder="100,000"
                                  required
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="accountName">Account Name</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="accountName"
                                  placeholder="Enter account name"
                                  required
                                  value={accountName}
                                  onChange={(e) =>
                                    setAccountName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="accountNumber">
                                Account Number
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  className="form-control"
                                  id="accountNumber"
                                  placeholder="Enter account number"
                                  required
                                  value={accountNumber}
                                  onChange={(e) =>
                                    setAccountNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="bankName">Bank Name</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="bankName"
                                  placeholder="Enter bank name"
                                  required
                                  value={bankName}
                                  onChange={(e) => setBankName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="accountType">Account Type</label>
                              <select
                                id="accountType"
                                className="custom-select"
                                required
                                value={accountType}
                                onChange={(e) => setAccountType(e.target.value)}
                              >
                                <option value="">--</option>
                                <option value="Savings account">
                                  Savings account
                                </option>
                                <option value="Current account">
                                  Current account
                                </option>
                                <option value="Checking account">
                                  Checking account
                                </option>
                                <option value="Fixed Deposit">
                                  Fixed Deposit
                                </option>
                                <option value="Non-resident account">
                                  Non-resident account
                                </option>
                                <option value="Online banking">
                                  Online banking
                                </option>
                                <option value="Domiciliary account">
                                  Domiciliary account
                                </option>
                                <option value="Joint account">
                                  Joint account
                                </option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="description">Description</label>
                              <div className="input-group">
                                <textarea
                                  id="description"
                                  className="form-control"
                                  placeholder="Description"
                                  required
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <p className="text-muted mt-4">
                              Transactions fees{" "}
                              <span className="float-right d-flex align-items-center">
                                <del>1.00 USD</del>{" "}
                                <span className="bg-info text-1 text-white font-weight-500 rounded d-inline-block px-2 line-height-4 ml-2">
                                  Free
                                </span>
                              </span>
                            </p>
                            <hr />
                            <p className="text-4 font-weight-500">
                              You'll send{" "}
                              <span className="float-right">{amount} USD</span>
                            </p>
                            <button className="btn btn-primary btn-block">
                              Continue
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  {stepOneCompleted &&
                    !stepTwoCompleted &&
                    !transferCompleted && (
                      <div className="row">
                        <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
                          <div className="bg-white shadow-sm rounded p-3 pt-sm-4 pb-sm-5 px-sm-5 mb-4">
                            <form
                              id="form-send-money"
                              onSubmit={(e) => {
                                e.preventDefault();
                                if (+amount <= +user.balance) {
                                  setStepTwoCompleted(true);
                                  sendLocalOtp();
                                } else {
                                  toast.error(
                                    "Insufficient balance. Please top up your account balance."
                                  );
                                }
                              }}
                            >
                              <div className="alert alert-info rounded shadow-sm py-3 px-4 px-sm-2 mb-5">
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Account Name:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {accountName}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Account Number:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {accountNumber}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Bank Name:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {bankName}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Account Type:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {accountType}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Description:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {description}
                                  </p>
                                </div>
                              </div>
                              <hr className="mx-n3 mx-sm-n5 mb-4" />
                              <h3 className="text-5 font-weight-400 mb-4">
                                Details
                              </h3>
                              <hr className="mx-n3 mx-sm-n5 mb-4" />
                              <p className="mb-1">
                                Transfer Amount{" "}
                                <span className="text-3 float-right">
                                  {amount} USD
                                </span>
                              </p>
                              <p className="mb-1">
                                Fees{" "}
                                <span className="text-3 float-right">
                                  0.00 USD
                                </span>
                              </p>
                              <hr />
                              <p className="text-4 font-weight-500">
                                Total
                                <span className="float-right">
                                  {amount} USD
                                </span>
                              </p>
                              <button className="btn btn-primary btn-block">
                                Confirm
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}

                  {transferCompleted && (
                    <div className="row">
                      <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
                        <div className="bg-white text-center shadow-sm rounded p-3 pt-sm-4 pb-sm-5 px-sm-5 mb-4">
                          <div className="my-4">
                            <p className="text-success text-20 line-height-07">
                              <i className="fas fa-check-circle" />
                            </p>
                            <p className="text-success text-8 font-weight-500 line-height-07">
                              Success!
                            </p>
                            <p className="lead">Transfer Request Submitted</p>
                          </div>
                          <p className="text-3 mb-4">
                            Your request to transfer
                            <span className="text-4 font-weight-500">
                              {" "}
                              ${amount}
                            </span>{" "}
                            is now being reviewed. See transaction details under{" "}
                            <Link className="btn-link" to="/transactions">
                              Transactions
                            </Link>
                            .
                          </p>
                          <button
                            className="btn btn-primary btn-block"
                            onClick={() => navigate(0)}
                          >
                            Transfer Money Again
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {tabActive === "International" && (
                <>
                  <div className="row mt-4 mb-5">
                    <div className="col-lg-11 mx-auto">
                      <div className="row widget-steps">
                        {!stepOneCompleted && !transferCompleted && (
                          <div className="col-4 step active">
                            <div className="step-name">Details</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                        {stepOneCompleted && (
                          <div className="col-4 step complete">
                            <div className="step-name">Details</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                        {!stepOneCompleted && !transferCompleted && (
                          <div className="col-4 step disabled">
                            <div className="step-name">Confirm</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                        {stepOneCompleted &&
                          !stepTwoCompleted &&
                          !transferCompleted && (
                            <div className="col-4 step active">
                              <div className="step-name">Confirm</div>
                              <div className="progress">
                                <div className="progress-bar" />
                              </div>
                              <a href="#/" className="step-dot">
                                {" "}
                              </a>
                            </div>
                          )}
                        {stepOneCompleted && stepTwoCompleted && (
                          <div className="col-4 step complete">
                            <div className="step-name">Confirm</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                        {!transferCompleted && (
                          <div className="col-4 step disabled">
                            <div className="step-name">Success</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                        {transferCompleted && (
                          <div className="col-4 step complete">
                            <div className="step-name">Success</div>
                            <div className="progress">
                              <div className="progress-bar" />
                            </div>
                            <a href="#/" className="step-dot">
                              {" "}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <h2 className="font-weight-400 text-center mt-3 mb-4">
                    Send money across borders
                  </h2>
                  {!stepOneCompleted && !transferCompleted && (
                    <div className="row">
                      <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
                        <div className="bg-white shadow-sm rounded p-3 pt-sm-5 pb-sm-5 px-sm-5 mb-4">
                          <form
                            id="form-send-money"
                            onSubmit={(e) => {
                              e.preventDefault();
                              setStepOneCompleted(true);
                            }}
                          >
                            <div className="form-group">
                              <label htmlFor="youSend">Amount</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  className="form-control"
                                  id="youSend"
                                  placeholder="100,000"
                                  required
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="accountName">Account Name</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="accountName"
                                  placeholder="Enter account name"
                                  required
                                  value={accountName}
                                  onChange={(e) =>
                                    setAccountName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="accountNumber">
                                Account Number
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  className="form-control"
                                  id="accountNumber"
                                  placeholder="Enter account number"
                                  required
                                  value={accountNumber}
                                  onChange={(e) =>
                                    setAccountNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="bankName">Bank Name</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="bankName"
                                  placeholder="Enter bank name"
                                  required
                                  value={bankName}
                                  onChange={(e) => setBankName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="routingNumber">
                                Routing Number
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  className="form-control"
                                  id="routingNumber"
                                  placeholder="Enter routing number"
                                  required
                                  value={routingNumber}
                                  onChange={(e) =>
                                    setRoutingNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="swiftCode">Swift Code</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="swiftCode"
                                  placeholder="Enter swift code"
                                  required
                                  value={swiftCode}
                                  onChange={(e) => setSwiftCode(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="country">Country</label>
                              <select
                                id="country"
                                className="custom-select"
                                required
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              >
                                <option value="">-</option>
                                <option value="Afganistan">Afghanistan</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">
                                  American Samoa
                                </option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antigua &amp; Barbuda">
                                  Antigua &amp; Barbuda
                                </option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bonaire">Bonaire</option>
                                <option value="Bosnia &amp; Herzegovina">
                                  Bosnia &amp; Herzegovina
                                </option>
                                <option value="Botswana">Botswana</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Ter">
                                  British Indian Ocean Ter
                                </option>
                                <option value="Brunei">Brunei</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">
                                  Burkina Faso
                                </option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Canary Islands">
                                  Canary Islands
                                </option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">
                                  Cayman Islands
                                </option>
                                <option value="Central African Republic">
                                  Central African Republic
                                </option>
                                <option value="Chad">Chad</option>
                                <option value="Channel Islands">
                                  Channel Islands
                                </option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">
                                  Christmas Island
                                </option>
                                <option value="Cocos Island">
                                  Cocos Island
                                </option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo">Congo</option>
                                <option value="Cook Islands">
                                  Cook Islands
                                </option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Cote DIvoire">
                                  Cote DIvoire
                                </option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Curaco">Curacao</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">
                                  Czech Republic
                                </option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">
                                  Dominican Republic
                                </option>
                                <option value="East Timor">East Timor</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">
                                  Equatorial Guinea
                                </option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands">
                                  Falkland Islands
                                </option>
                                <option value="Faroe Islands">
                                  Faroe Islands
                                </option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">
                                  French Guiana
                                </option>
                                <option value="French Polynesia">
                                  French Polynesia
                                </option>
                                <option value="French Southern Ter">
                                  French Southern Ter
                                </option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Great Britain">
                                  Great Britain
                                </option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Hawaii">Hawaii</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="India">India</option>
                                <option value="Iran">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle of Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Korea North">Korea North</option>
                                <option value="Korea Sout">Korea South</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Laos">Laos</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libya">Libya</option>
                                <option value="Liechtenstein">
                                  Liechtenstein
                                </option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macau">Macau</option>
                                <option value="Macedonia">Macedonia</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">
                                  Marshall Islands
                                </option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Midway Islands">
                                  Midway Islands
                                </option>
                                <option value="Moldova">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Nambia">Nambia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherland Antilles">
                                  Netherland Antilles
                                </option>
                                <option value="Netherlands">
                                  Netherlands (Holland, Europe)
                                </option>
                                <option value="Nevis">Nevis</option>
                                <option value="New Caledonia">
                                  New Caledonia
                                </option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">
                                  Norfolk Island
                                </option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau Island">
                                  Palau Island
                                </option>
                                <option value="Palestine">Palestine</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">
                                  Papua New Guinea
                                </option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Phillipines">Philippines</option>
                                <option value="Pitcairn Island">
                                  Pitcairn Island
                                </option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Republic of Montenegro">
                                  Republic of Montenegro
                                </option>
                                <option value="Republic of Serbia">
                                  Republic of Serbia
                                </option>
                                <option value="Reunion">Reunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russia">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="St Barthelemy">
                                  St Barthelemy
                                </option>
                                <option value="St Eustatius">
                                  St Eustatius
                                </option>
                                <option value="St Helena">St Helena</option>
                                <option value="St Kitts-Nevis">
                                  St Kitts-Nevis
                                </option>
                                <option value="St Lucia">St Lucia</option>
                                <option value="St Maarten">St Maarten</option>
                                <option value="St Pierre &amp; Miquelon">
                                  St Pierre &amp; Miquelon
                                </option>
                                <option value="St Vincent &amp; Grenadines">
                                  St Vincent &amp; Grenadines
                                </option>
                                <option value="Saipan">Saipan</option>
                                <option value="Samoa">Samoa</option>
                                <option value="Samoa American">
                                  Samoa American
                                </option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome &amp; Principe">
                                  Sao Tome &amp; Principe
                                </option>
                                <option value="Saudi Arabia">
                                  Saudi Arabia
                                </option>
                                <option value="Senegal">Senegal</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">
                                  Sierra Leone
                                </option>
                                <option value="Singapore">Singapore</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">
                                  Solomon Islands
                                </option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">
                                  South Africa
                                </option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Swaziland">Swaziland</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syria">Syria</option>
                                <option value="Tahiti">Tahiti</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad &amp; Tobago">
                                  Trinidad &amp; Tobago
                                </option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">
                                  Turkmenistan
                                </option>
                                <option value="Turks &amp; Caicos Is">
                                  Turks &amp; Caicos Is
                                </option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="United Kingdom">
                                  United Kingdom
                                </option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Erimates">
                                  United Arab Emirates
                                </option>
                                <option value="United States of America">
                                  United States of America
                                </option>
                                <option value="Uraguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Vatican City State">
                                  Vatican City State
                                </option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Vietnam">Vietnam</option>
                                <option value="Virgin Islands (Brit)">
                                  Virgin Islands (Brit)
                                </option>
                                <option value="Virgin Islands (USA)">
                                  Virgin Islands (USA)
                                </option>
                                <option value="Wake Island">Wake Island</option>
                                <option value="Wallis &amp; Futana Is">
                                  Wallis &amp; Futana Is
                                </option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zaire">Zaire</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="accountType">Account Type</label>
                              <select
                                id="accountType"
                                className="custom-select"
                                required
                                value={accountType}
                                onChange={(e) => setAccountType(e.target.value)}
                              >
                                <option value="">--</option>
                                <option value="Savings account">
                                  Savings account
                                </option>
                                <option value="Current account">
                                  Current account
                                </option>
                                <option value="Checking account">
                                  Checking account
                                </option>
                                <option value="Fixed Deposit">
                                  Fixed Deposit
                                </option>
                                <option value="Non-resident account">
                                  Non-resident account
                                </option>
                                <option value="Online banking">
                                  Online banking
                                </option>
                                <option value="Domiciliary account">
                                  Domiciliary account
                                </option>
                                <option value="Joint account">
                                  Joint account
                                </option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="description">Description</label>
                              <div className="input-group">
                                <textarea
                                  id="description"
                                  className="form-control"
                                  placeholder="Description"
                                  required
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <p className="text-muted mt-4">
                              Transactions fees{" "}
                              <span className="float-right d-flex align-items-center">
                                <del>1.00 USD</del>{" "}
                                <span className="bg-info text-1 text-white font-weight-500 rounded d-inline-block px-2 line-height-4 ml-2">
                                  Free
                                </span>
                              </span>
                            </p>
                            <hr />
                            <p className="text-4 font-weight-500">
                              You'll send{" "}
                              <span className="float-right">{amount} USD</span>
                            </p>
                            <button className="btn btn-primary btn-block">
                              Continue
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  {stepOneCompleted &&
                    !stepTwoCompleted &&
                    !transferCompleted && (
                      <div className="row">
                        <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
                          <div className="bg-white shadow-sm rounded p-3 pt-sm-4 pb-sm-5 px-sm-5 mb-4">
                            <form
                              id="form-send-money"
                              onSubmit={(e) => {
                                e.preventDefault();
                                if (+amount <= +user.balance) {
                                  setStepTwoCompleted(true);
                                  sendInternationalOtp();
                                } else {
                                  toast.error(
                                    "Insufficient balance. Please top up your account balance."
                                  );
                                }
                              }}
                            >
                              <div className="alert alert-info rounded shadow-sm py-3 px-4 px-sm-2 mb-5">
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Account Name:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {accountName}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Account Number:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {accountNumber}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Bank Name:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {bankName}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Routing Number:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {routingNumber}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Swift Code:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {swiftCode}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Country:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {country}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Account Type:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {accountType}
                                  </p>
                                </div>
                                <div className="form-row align-items-center">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0">
                                    Description:
                                  </p>
                                  <p className="col-sm-7 text-3 mb-0">
                                    {description}
                                  </p>
                                </div>
                              </div>
                              <hr className="mx-n3 mx-sm-n5 mb-4" />
                              <h3 className="text-5 font-weight-400 mb-4">
                                Details
                              </h3>
                              <hr className="mx-n3 mx-sm-n5 mb-4" />
                              <p className="mb-1">
                                Transfer Amount{" "}
                                <span className="text-3 float-right">
                                  {amount} USD
                                </span>
                              </p>
                              <p className="mb-1">
                                Fees{" "}
                                <span className="text-3 float-right">
                                  0.00 USD
                                </span>
                              </p>
                              <hr />
                              <p className="text-4 font-weight-500">
                                Total
                                <span className="float-right">
                                  {amount} USD
                                </span>
                              </p>
                              <button className="btn btn-primary btn-block">
                                Confirm
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}

                  {transferCompleted && (
                    <div className="row">
                      <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
                        <div className="bg-white text-center shadow-sm rounded p-3 pt-sm-4 pb-sm-5 px-sm-5 mb-4">
                          <div className="my-4">
                            <p className="text-success text-20 line-height-07">
                              <i className="fas fa-check-circle" />
                            </p>
                            <p className="text-success text-8 font-weight-500 line-height-07">
                              Success!
                            </p>
                            <p className="lead">Transfer Request Submitted</p>
                          </div>
                          <p className="text-3 mb-4">
                            Your request to transfer
                            <span className="text-4 font-weight-500">
                              {" "}
                              ${amount}
                            </span>{" "}
                            is now being reviewed. See transaction details under{" "}
                            <Link className="btn-link" to="/transactions">
                              Transactions
                            </Link>
                            .
                          </p>
                          <button
                            className="btn btn-primary btn-block"
                            onClick={() => navigate(0)}
                          >
                            Transfer Money Again
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <Footer />
      </div>
      <BackToTop />

      {otpModalOpen && !transferCompleted && (
        <>
          <div className="modal-backdrop fade show" />
          <div
            id="notifications-detail"
            className="modal fade show"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
              role="document"
            >
              <form onSubmit={checkOtp}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title d-flex align-items-center font-weight-400">
                      <span className="text-6 mr-2">
                        <i className="far fa-bell" />
                      </span>{" "}
                      Transfer Verification
                    </h5>
                  </div>
                  <div className="modal-body p-4 text-3">
                    <div className="row">
                      <div className="col-sm-12">
                        <p>
                          We sent an OTP to your email address. Please input the
                          code received below to complete your transfer.
                        </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="otp">Enter code here:</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="otp"
                          placeholder="123456"
                          required
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="btn-group m-0 w-100 row">
                      <button
                        type="submit"
                        className="btn btn-primary shadow-none col-12"
                      >
                        <span className="mr-1"></span>
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </>
  );
};

export default SendMoney;
